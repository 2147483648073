var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("Navbar"),
      _vm._v(" "),
      _vm.getBrand && _vm.getCampaigns
        ? _c(
            "v-container",
            { attrs: { "pa-0": "" } },
            [
              _c(
                "v-layout",
                { staticClass: "mt-60" },
                [
                  _c(
                    "v-container",
                    {
                      staticClass: "pa-2 pb-0 border_title hidden-md-and-down"
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            "align-end": "",
                            "justify-space-between": "",
                            row: "",
                            "fill-height": ""
                          }
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", md6: "", "pa-2": "" } },
                            [
                              _c(
                                "h5",
                                {
                                  staticClass:
                                    "display-1 text-xs-left  primary--text font-weight-thin"
                                },
                                [_vm._v("Campaña")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            {
                              staticClass: "logo-campaing",
                              attrs: { xs12: "", md6: "" }
                            },
                            [
                              _c("v-img", {
                                attrs: { src: _vm.getBrand.photo, width: "100" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { staticClass: "pa-2 pb-0 hidden-lg-and-up" },
                    [
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs12: "",
                            md6: "",
                            "pa-2": "",
                            border_title: ""
                          }
                        },
                        [
                          _c(
                            "h5",
                            {
                              staticClass:
                                "title text-xs-left  primary--text font-weight-thin"
                            },
                            [_vm._v("Campaña")]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-layout",
                        { attrs: { "align-center": "", "justify-center": "" } },
                        [
                          _c(
                            "div",
                            { staticClass: "pa-3" },
                            [
                              _c("v-img", {
                                attrs: { src: _vm.getBrand.photo, width: "100" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-layout",
                { attrs: { row: "", container: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "", "justify-center": "" } },
                        _vm._l(_vm.getCampaigns, function(campaign, i) {
                          return _c(
                            "v-flex",
                            {
                              key: i,
                              staticStyle: { position: "relative" },
                              attrs: {
                                xs12: "",
                                sm6: "",
                                md4: "",
                                "pa-3": "",
                                "d-flex": ""
                              }
                            },
                            [
                              _c("CardCampaign", {
                                attrs: { campaign: campaign }
                              })
                            ],
                            1
                          )
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _vm.getCampaigns.length == 0
                        ? _c(
                            "v-layout",
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _vm._v(
                                  "\n            Aún no hay campañas en las que puedas participar\n          "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-layout",
            {
              attrs: {
                row: "",
                "fill-height": "",
                "align-center": "",
                "xs-minvh90": ""
              }
            },
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/landing/loading.gif"),
                    height: "100"
                  }
                })
              ])
            ],
            1
          ),
      _vm._v(" "),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }