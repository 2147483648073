var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("Navbar", { on: { goTo: _vm.goTo } }),
      _vm._v(" "),
      _vm.getCampaign
        ? _c(
            "div",
            [
              _c(
                "v-layout",
                {
                  staticClass: "mt-60 banner-detail",
                  style: _vm.img_section_style
                },
                [
                  _c(
                    "v-container",
                    { attrs: { "mt-0": "", "pt-0": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", md5: "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                row: "",
                                wrap: "",
                                "pt-3": "",
                                "pb-0": "",
                                "px-2": "",
                                "text-sm-left": ""
                              }
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        row: "",
                                        wrap: "",
                                        "text-sm-left": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { "md-5": "", "xs-12": "" } },
                                        [
                                          _vm.getCampaign.brand.photo
                                            ? _c("img", {
                                                staticClass:
                                                  "img-brand-campaign",
                                                attrs: {
                                                  src:
                                                    _vm.getCampaign.brand.photo,
                                                  height: "40"
                                                }
                                              })
                                            : _vm._e()
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.getCampaign
        ? _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "", "justify-center": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { id: "participate", md6: "", x12: "" } },
                    [
                      _c(
                        "v-container",
                        { attrs: { "pl-2": "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "text-sm-left": "" } },
                            [
                              _c(
                                "h2",
                                {
                                  staticClass:
                                    "primary--text font-weight-black pb-3 display-1"
                                },
                                [_vm._v(_vm._s(_vm.getCampaign.name))]
                              ),
                              _vm._v(" "),
                              _c("h2", {
                                staticClass:
                                  "primary--text font-weight-black pb-4 subheading",
                                domProps: {
                                  innerHTML: _vm._s(_vm.getCampaign.resume)
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    row: "",
                                    wrap: "",
                                    "text-sm-left": ""
                                  }
                                },
                                [
                                  _vm.getCampaign.caption_detail
                                    ? _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            sm12: "",
                                            "pa-3": "",
                                            primary: "",
                                            "mb-4": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                row: "",
                                                wrap: "",
                                                "justify-center": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs12: "",
                                                    "px-1": "",
                                                    "white--text": "",
                                                    subheading: ""
                                                  }
                                                },
                                                [
                                                  _c("v-content", {
                                                    staticClass:
                                                      "font-weight-thin",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.getCampaign
                                                          .caption_detail
                                                      )
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs12: "" } }, [
                                    _c("span", {
                                      staticClass: "subheading",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getCampaign.description
                                        )
                                      }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _vm.getCampaign.closing
                                    ? _c(
                                        "v-flex",
                                        { attrs: { xs12: "", "mt-4": "" } },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                row: "",
                                                wrap: "",
                                                "justify-center": "",
                                                subheading: ""
                                              }
                                            },
                                            [
                                              _c("v-flex", {
                                                attrs: { xs12: "" },
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.getCampaign.closing.text
                                                  )
                                                }
                                              }),
                                              _vm._v(" "),
                                              _vm.getCampaign.closing.button
                                                ? _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        "pt-2": ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href:
                                                              _vm.getCampaign
                                                                .closing.button
                                                                .url,
                                                            target: "_blank"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mx-0",
                                                              attrs: {
                                                                color: "primary"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .getCampaign
                                                                    .closing
                                                                    .button.text
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.getCampaign.closing.caption
                                                ? _c("v-flex", {
                                                    staticClass: "caption",
                                                    attrs: {
                                                      xs12: "",
                                                      "pt-3": ""
                                                    },
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.getCampaign.closing
                                                          .caption
                                                      )
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.getCampaign.terms_url
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass: "mt-5",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "underline",
                                              attrs: {
                                                href: _vm.getCampaign.terms_url,
                                                target: "_blank"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "Términos y condiciones de la campaña"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.getCampaign.privacy_policy_url
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass: "mt-1",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "underline",
                                              attrs: {
                                                href:
                                                  _vm.getCampaign
                                                    .privacy_policy_url,
                                                target: "_blank"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "Política de privacidad de la campaña"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    {
                      staticClass: "form-campaing",
                      attrs: { md6: "", x12: "" }
                    },
                    [
                      _vm.getCampaign.type == "informative" &&
                      _vm.getCampaign.info_extra
                        ? _c(
                            "v-layout",
                            {
                              attrs: {
                                white: "",
                                "shadow-lg": "",
                                "align-center": "",
                                "justify-center": "",
                                row: "",
                                wrap: ""
                              }
                            },
                            [
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    "pa-5": "",
                                    "align-center": ""
                                  }
                                },
                                [
                                  _c("h3", { staticClass: "subheading mb-3" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.getCampaign.info_extra
                                            .reference_text
                                        ) +
                                        "\n            "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          _vm.getCampaign.info_extra
                                            .reference_button.url,
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        { attrs: { color: "primary" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getCampaign.info_extra
                                                .reference_button.text
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : !_vm.getCampaign.is_valid
                        ? _c(
                            "v-layout",
                            {
                              attrs: {
                                white: "",
                                "shadow-lg": "",
                                "align-center": "",
                                "justify-center": "",
                                row: "",
                                wrap: ""
                              }
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { primary: "", xs12: "" } },
                                [
                                  _c(
                                    "h2",
                                    {
                                      staticClass:
                                        "display-1 white--text font-weight-thin py-4"
                                    },
                                    [_vm._v("¡Próximamente!")]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    "pa-5": "",
                                    "align-center": ""
                                  }
                                },
                                [
                                  _c("div", { staticClass: "py-4" }, [
                                    _c("h3", { staticClass: "subheading" }, [
                                      _vm._v(
                                        "Podrás participar en esta campaña a partir del:"
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("h3", { staticClass: "subtitle" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.formatDate(
                                              _vm.getCampaign.date_start
                                            )
                                          ) +
                                          " al\n                " +
                                          _vm._s(
                                            _vm.formatDate(
                                              _vm.getCampaign.date_end
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ])
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm.getCampaign.outmiles
                        ? _c(
                            "v-layout",
                            {
                              attrs: {
                                white: "",
                                "shadow-lg": "",
                                "align-center": "",
                                "justify-center": "",
                                row: "",
                                "fill-height": ""
                              }
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", "pa-3": "", "my-5": "" } },
                                [
                                  _c("h3", { staticClass: "subheading" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "primary--text font-weight-bold"
                                      },
                                      [_vm._v("Lo sentimos")]
                                    ),
                                    _vm._v(
                                      ", pero ya no podrás participar en esta\n              campaña debido a que ya no hay millas disponibles.\n            "
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _c(
                            "v-content",
                            { staticStyle: { position: "relative" } },
                            [
                              _vm.getUser
                                ? _c(
                                    "v-flex",
                                    [
                                      _c(
                                        "h2",
                                        {
                                          staticClass:
                                            "display-1 primary white--text font-weight-thin py-4"
                                        },
                                        [_vm._v("¡Participa de la promoción!")]
                                      ),
                                      _vm._v(" "),
                                      _vm.participation
                                        ? _c(
                                            "v-form",
                                            {
                                              ref: "formParticipate",
                                              staticClass:
                                                "white shadow-lg border-content"
                                            },
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  staticClass: "content-form",
                                                  attrs: {
                                                    row: "",
                                                    wrap: "",
                                                    "mx-4": "",
                                                    "mb-4": ""
                                                  }
                                                },
                                                [
                                                  _vm.getCampaign.type !==
                                                    "tokenized" &&
                                                  _vm.getCampaign.type !==
                                                    "only_terms"
                                                    ? _c(
                                                        "v-flex",
                                                        { attrs: { xs12: "" } },
                                                        [
                                                          _c(
                                                            "v-layout",
                                                            {
                                                              attrs: {
                                                                row: "",
                                                                wrap: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs12: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        rules:
                                                                          _vm
                                                                            .rules
                                                                            .required,
                                                                        disabled:
                                                                          "",
                                                                        label:
                                                                          "Nombres y Apellidos",
                                                                        placeholder:
                                                                          " ",
                                                                        readonly:
                                                                          ""
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .user
                                                                            .full_name,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.user,
                                                                            "full_name",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "user.full_name"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs12: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        rules:
                                                                          _vm
                                                                            .rules
                                                                            .required,
                                                                        disabled:
                                                                          "",
                                                                        label:
                                                                          "Correo electrónico",
                                                                        placeholder:
                                                                          " ",
                                                                        readonly:
                                                                          ""
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .user
                                                                            .email,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.user,
                                                                            "email",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "user.email"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs12: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        rules:
                                                                          _vm
                                                                            .rules
                                                                            .required,
                                                                        disabled:
                                                                          "",
                                                                        label:
                                                                          "Fecha de Nacimiento",
                                                                        placeholder:
                                                                          " ",
                                                                        mask:
                                                                          "##-##-####",
                                                                        readonly:
                                                                          ""
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .user
                                                                            .birthdate,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.user,
                                                                            "birthdate",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "user.birthdate"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs12: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        rules:
                                                                          _vm
                                                                            .rules
                                                                            .required,
                                                                        disabled:
                                                                          "",
                                                                        label:
                                                                          "Teléfono",
                                                                        placeholder:
                                                                          " ",
                                                                        readonly:
                                                                          ""
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .user
                                                                            .phone,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.user,
                                                                            "phone",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "user.phone"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _vm.getCampaign
                                                                .validate_only_dni
                                                                ? _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs12: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs: {
                                                                            label:
                                                                              _vm
                                                                                .user
                                                                                .type_document,
                                                                            rules:
                                                                              _vm
                                                                                .rules
                                                                                .required,
                                                                            disabled:
                                                                              "",
                                                                            placeholder:
                                                                              " ",
                                                                            readonly:
                                                                              ""
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .user
                                                                                .number_document,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.user,
                                                                                "number_document",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "user.number_document"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _vm.getCampaign
                                                                .stores
                                                                ? _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs12: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-select",
                                                                        {
                                                                          attrs: {
                                                                            items:
                                                                              _vm
                                                                                .getCampaign
                                                                                .stores,
                                                                            "item-text":
                                                                              "name",
                                                                            "item-value":
                                                                              "name",
                                                                            label:
                                                                              "Tienda en donde adquiriste los productos",
                                                                            placeholder:
                                                                              "Seleccione una tienda",
                                                                            rules:
                                                                              _vm
                                                                                .rules
                                                                                .required
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .record
                                                                                .store,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.record,
                                                                                "store",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "record.store"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _vm.getCampaign
                                                                .custom_stores
                                                                ? _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs12: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-select",
                                                                        {
                                                                          attrs: {
                                                                            items:
                                                                              _vm
                                                                                .getCampaign
                                                                                .custom_stores,
                                                                            "item-text":
                                                                              "name",
                                                                            "item-value":
                                                                              "name",
                                                                            label:
                                                                              "Tienda en donde adquiriste los productos",
                                                                            placeholder:
                                                                              "Seleccione una tienda",
                                                                            rules:
                                                                              _vm
                                                                                .rules
                                                                                .required
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .record
                                                                                .custom_store,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.record,
                                                                                "custom_store",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "record.custom_store"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs12: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-menu",
                                                                    {
                                                                      attrs: {
                                                                        "close-on-content-click": true,
                                                                        "max-width":
                                                                          "290px"
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var attrs =
                                                                                ref.attrs
                                                                              return [
                                                                                _c(
                                                                                  "v-text-field",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        attrs: {
                                                                                          label:
                                                                                            "Fecha de boleta",
                                                                                          placeholder:
                                                                                            " ",
                                                                                          readonly:
                                                                                            ""
                                                                                        },
                                                                                        model: {
                                                                                          value:
                                                                                            _vm
                                                                                              .record
                                                                                              .voucher_date,
                                                                                          callback: function(
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm.record,
                                                                                              "voucher_date",
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                          expression:
                                                                                            "record.voucher_date"
                                                                                        }
                                                                                      },
                                                                                      "v-text-field",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  )
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        false,
                                                                        1824237281
                                                                      ),
                                                                      model: {
                                                                        value:
                                                                          _vm.menuVoucherDate,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.menuVoucherDate = $$v
                                                                        },
                                                                        expression:
                                                                          "menuVoucherDate"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _vm.menuVoucherDate
                                                                        ? _c(
                                                                            "v-date-picker",
                                                                            {
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .record
                                                                                    .voucher_date,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.record,
                                                                                    "voucher_date",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "record.voucher_date"
                                                                              }
                                                                            }
                                                                          )
                                                                        : _vm._e()
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              !_vm.getCampaign
                                                                .disabled_voucher
                                                                ? _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs12: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs: {
                                                                            rules:
                                                                              _vm
                                                                                .rules
                                                                                .voucher,
                                                                            disabled: !_vm.getUser,
                                                                            label:
                                                                              "Número de Boleta/Factura",
                                                                            placeholder:
                                                                              " ",
                                                                            hint:
                                                                              "* No debe incluir espacios en blanco o caracteres especiales"
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .record
                                                                                .voucher,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.record,
                                                                                "voucher",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "record.voucher"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              !_vm.getCampaign
                                                                .disabled_voucher_image
                                                                ? _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs12:
                                                                          "",
                                                                        "d-flex":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "w100 pointer",
                                                                          attrs: {
                                                                            disabled: !_vm.getUser,
                                                                            rules:
                                                                              _vm
                                                                                .rules
                                                                                .required,
                                                                            label:
                                                                              "Foto de Boleta/Factura",
                                                                            placeholder:
                                                                              "Subir una foto",
                                                                            readonly:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click:
                                                                              _vm.pickFile
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.imageName,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.imageName = $$v
                                                                            },
                                                                            expression:
                                                                              "imageName"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs: {
                                                                                icon:
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                                disabled: !_vm.getUser
                                                                              },
                                                                              on: {
                                                                                click:
                                                                                  _vm.pickFile
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    "photo"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          ref:
                                                                            "imageVoucher",
                                                                          staticStyle: {
                                                                            display:
                                                                              "none"
                                                                          },
                                                                          attrs: {
                                                                            type:
                                                                              "file",
                                                                            accept:
                                                                              "application/pdf, image/*"
                                                                          },
                                                                          on: {
                                                                            change:
                                                                              _vm.onFilePicked
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              (
                                                                _vm.getCampaign
                                                                  .custom_fields ||
                                                                []
                                                              ).includes(
                                                                "district_plaza_vea"
                                                              )
                                                                ? _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs12: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-select",
                                                                        {
                                                                          attrs: {
                                                                            items: [
                                                                              "Tienda Online",
                                                                              "Ancon",
                                                                              "Ate",
                                                                              "Barranco",
                                                                              "Breña",
                                                                              "Carabayllo",
                                                                              "Chaclacayo",
                                                                              "Chorrillos",
                                                                              "Cieneguilla",
                                                                              "Comas",
                                                                              "El Agustino",
                                                                              "Independencia",
                                                                              "Jesus Maria",
                                                                              "La Molina",
                                                                              "La Victoria",
                                                                              "Lima",
                                                                              "Lince",
                                                                              "Los Olivos",
                                                                              "Lurigancho",
                                                                              "Lurin",
                                                                              "Magdalena del Mar",
                                                                              "Miraflores",
                                                                              "Pachacamac",
                                                                              "Pucusana",
                                                                              "Pueblo Libre",
                                                                              "Puente Piedra",
                                                                              "Punta Hermosa",
                                                                              "Punta Negra",
                                                                              "Rimac (solo urb. piedra liza)",
                                                                              "rimac (excepto urb. pied. liza)",
                                                                              "San Bartolo",
                                                                              "san borja",
                                                                              "San Isidro",
                                                                              "San Juan de Lurigancho",
                                                                              "San Juan de Miraflores",
                                                                              "San Luis",
                                                                              "San Martin de Porres",
                                                                              "San Miguel",
                                                                              "Santa Anita",
                                                                              "Santa Rosa",
                                                                              "Santiago de Surco",
                                                                              "Surquillo",
                                                                              "Villa el Salvador",
                                                                              "Villa Maria del Triunfo"
                                                                            ],
                                                                            label:
                                                                              "Distrito de Tienda Plaza Vea",
                                                                            placeholder:
                                                                              "Seleccione distrito",
                                                                            rules:
                                                                              _vm
                                                                                .rules
                                                                                .required
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .record
                                                                                .district_plaza_vea,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.record,
                                                                                "district_plaza_vea",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "record.district_plaza_vea"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              (
                                                                _vm.getCampaign
                                                                  .custom_fields ||
                                                                []
                                                              ).includes(
                                                                "number_of_bottles"
                                                              )
                                                                ? _c(
                                                                    "v-flex",
                                                                    {
                                                                      class: _vm
                                                                        .getCampaign
                                                                        .ratio
                                                                        ? "md8 xs7"
                                                                        : "xs12"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-select",
                                                                        {
                                                                          attrs: {
                                                                            items: [
                                                                              "1",
                                                                              "2",
                                                                              "3"
                                                                            ],
                                                                            label:
                                                                              "Cantidad de botellas compradas",
                                                                            placeholder:
                                                                              "Seleccione cantidad",
                                                                            rules:
                                                                              _vm
                                                                                .rules
                                                                                .required
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .record
                                                                                .number_of_bottles,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.record,
                                                                                "number_of_bottles",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "record.number_of_bottles"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              !_vm.getCampaign
                                                                .disabled_amount
                                                                ? _c(
                                                                    "v-flex",
                                                                    {
                                                                      class: _vm
                                                                        .getCampaign
                                                                        .ratio
                                                                        ? "md8 xs7"
                                                                        : "xs12"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs: {
                                                                            rules:
                                                                              _vm
                                                                                .rules
                                                                                .amount,
                                                                            disabled: !_vm.getUser,
                                                                            label:
                                                                              "Monto total de la compra",
                                                                            prefix:
                                                                              "S/. ",
                                                                            placeholder:
                                                                              " ",
                                                                            hint:
                                                                              _vm
                                                                                .getCampaign
                                                                                .min_amount >
                                                                              0
                                                                                ? "Monto mínimo de " +
                                                                                  _vm.formatCurrency(
                                                                                    _vm
                                                                                      .getCampaign
                                                                                      .min_amount
                                                                                  )
                                                                                : ""
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .record
                                                                                .amount,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.record,
                                                                                "amount",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "record.amount"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _vm.record
                                                                .amount &&
                                                              _vm.getCampaign
                                                                .redeem &&
                                                              _vm.getCampaign
                                                                .redeem.amount >
                                                                _vm.record
                                                                  .amount
                                                                ? _c(
                                                                    "v-flex",
                                                                    {
                                                                      staticClass:
                                                                        "xs12"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-alert",
                                                                        {
                                                                          staticClass:
                                                                            "text-xs-left font-weight-bold",
                                                                          attrs: {
                                                                            value: true,
                                                                            color:
                                                                              "warning",
                                                                            icon:
                                                                              "warning"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                        " +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .getCampaign
                                                                                  .redeem
                                                                                  .text
                                                                              ) +
                                                                              "\n                      "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _vm.getCampaign
                                                                .ratio
                                                                ? _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        md4: "",
                                                                        xs5: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "border-primary border-round py-1",
                                                                          attrs: {
                                                                            color:
                                                                              "primary"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "h6",
                                                                            {
                                                                              staticClass:
                                                                                "caption mb-0"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Podrás ganar"
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticClass:
                                                                                "size16",
                                                                              attrs: {
                                                                                small:
                                                                                  "",
                                                                                color:
                                                                                  "primary"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "local_airport"
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "strong",
                                                                            {
                                                                              staticClass:
                                                                                "size16"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .record
                                                                                    .miles
                                                                                ) +
                                                                                  " millas"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    "text-xs-left":
                                                                      "",
                                                                    "py-2": "",
                                                                    "pb-3": "",
                                                                    caption: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "* La información brindada en este formulario será validada, previo a la acreditación de\n                      millas."
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs12: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-alert",
                                                                    {
                                                                      attrs: {
                                                                        value:
                                                                          _vm.insufficientMiles,
                                                                        color:
                                                                          "error",
                                                                        icon:
                                                                          "warning",
                                                                        outline:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                        Lo sentimos, pero no se le puede asignar esa cantidad de millas a su cuenta debido al límite\n                        de millas para esta campaña.\n                      "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs12: "" } },
                                                    [
                                                      _vm.participate_record
                                                        ? _c(
                                                            "v-layout",
                                                            {
                                                              attrs: {
                                                                row: "",
                                                                wrap: ""
                                                              }
                                                            },
                                                            [
                                                              _vm.token
                                                                ? _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs12:
                                                                          "",
                                                                        subheading:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                      Tu código promocional en esta campaña es\n                      "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "primary--text font-weight-bold"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.token
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _vm.getCampaign
                                                                .show_ranking
                                                                ? _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs12: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-layout",
                                                                        {
                                                                          attrs: {
                                                                            row:
                                                                              "",
                                                                            wrap:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-flex",
                                                                            {
                                                                              attrs: {
                                                                                xs12:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "h3",
                                                                                {
                                                                                  staticClass:
                                                                                    "title py-4 font-weight-black"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Ranking de participantes"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _vm.records
                                                                            ? _c(
                                                                                "v-flex",
                                                                                {
                                                                                  attrs: {
                                                                                    xs12:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-data-table",
                                                                                    {
                                                                                      attrs: {
                                                                                        headers:
                                                                                          _vm.headers,
                                                                                        items:
                                                                                          _vm.records,
                                                                                        "hide-actions": true
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "items",
                                                                                            fn: function(
                                                                                              props
                                                                                            ) {
                                                                                              return [
                                                                                                _c(
                                                                                                  "td",
                                                                                                  {
                                                                                                    class:
                                                                                                      props
                                                                                                        .item
                                                                                                        .classes
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                                " +
                                                                                                        _vm._s(
                                                                                                          props
                                                                                                            .item
                                                                                                            .position +
                                                                                                            1
                                                                                                        ) +
                                                                                                        "\n                              "
                                                                                                    )
                                                                                                  ]
                                                                                                ),
                                                                                                _vm._v(
                                                                                                  " "
                                                                                                ),
                                                                                                _c(
                                                                                                  "td",
                                                                                                  {
                                                                                                    class:
                                                                                                      props
                                                                                                        .item
                                                                                                        .classes
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                                " +
                                                                                                        _vm._s(
                                                                                                          props
                                                                                                            .item
                                                                                                            .user_id ===
                                                                                                            _vm
                                                                                                              .getUser
                                                                                                              .uid
                                                                                                            ? props
                                                                                                                .item
                                                                                                                .user
                                                                                                                .full_name
                                                                                                            : "Anonymous"
                                                                                                        ) +
                                                                                                        "\n                              "
                                                                                                    )
                                                                                                  ]
                                                                                                ),
                                                                                                _vm._v(
                                                                                                  " "
                                                                                                ),
                                                                                                _c(
                                                                                                  "td",
                                                                                                  {
                                                                                                    class:
                                                                                                      props
                                                                                                        .item
                                                                                                        .classes
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                                " +
                                                                                                        _vm._s(
                                                                                                          _vm.formatCurrency(
                                                                                                            props
                                                                                                              .item
                                                                                                              .amount
                                                                                                          )
                                                                                                        ) +
                                                                                                        "\n                              "
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            }
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        false,
                                                                                        2008708617
                                                                                      )
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e()
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs12:
                                                                          "",
                                                                        subheading:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                      Gracias por participar en la campaña\n                    "
                                                                      )
                                                                    ]
                                                                  )
                                                            ],
                                                            1
                                                          )
                                                        : _c(
                                                            "v-layout",
                                                            {
                                                              attrs: {
                                                                row: "",
                                                                wrap: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs12: "",
                                                                    "d-flex": ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-checkbox",
                                                                    {
                                                                      staticClass:
                                                                        "mt-1",
                                                                      attrs: {
                                                                        color:
                                                                          "primary",
                                                                        disabled: !_vm.getUser,
                                                                        rules:
                                                                          _vm
                                                                            .rules
                                                                            .required
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .record
                                                                            .terms,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.record,
                                                                            "terms",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "record.terms"
                                                                      }
                                                                    }
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "terms"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                        Acepto los\n                        "
                                                                      ),
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "underline",
                                                                          attrs: {
                                                                            href:
                                                                              _vm
                                                                                .getCampaign
                                                                                .terms_url,
                                                                            target:
                                                                              "_blank"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                          términos y condiciones\n                        "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        "\n                        de la campaña\n                        "
                                                                      ),
                                                                      _vm
                                                                        .getCampaign
                                                                        .privacy_policy_url
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                          y la\n                          "
                                                                              ),
                                                                              _c(
                                                                                "a",
                                                                                {
                                                                                  staticClass:
                                                                                    "underline",
                                                                                  attrs: {
                                                                                    href:
                                                                                      _vm
                                                                                        .getCampaign
                                                                                        .privacy_policy_url,
                                                                                    target:
                                                                                      "_blank"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "política de privacidad"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        "\n                        de " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .getCampaign
                                                                              .brand
                                                                              .name
                                                                          ) +
                                                                          "\n                      "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              (
                                                                _vm.getCampaign
                                                                  .custom_fields ||
                                                                []
                                                              ).includes(
                                                                "newsletter"
                                                              )
                                                                ? _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs12:
                                                                          "",
                                                                        "d-flex":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-checkbox",
                                                                        {
                                                                          staticClass:
                                                                            "mt-1",
                                                                          attrs: {
                                                                            color:
                                                                              "primary",
                                                                            disabled: !_vm.getUser
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .record
                                                                                .newsletter,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.record,
                                                                                "newsletter",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "record.newsletter"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "p",
                                                                        {
                                                                          staticClass:
                                                                            "terms"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                        Acepto recibir noticias e información promocional de " +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .getCampaign
                                                                                  .brand
                                                                                  .name
                                                                              ) +
                                                                              ".\n                      "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _vm.responseMessage
                                                                ? _c(
                                                                    "v-alert",
                                                                    {
                                                                      attrs: {
                                                                        value: true,
                                                                        color:
                                                                          "error",
                                                                        icon:
                                                                          "warning",
                                                                        outline:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                      " +
                                                                          _vm._s(
                                                                            _vm.responseMessage
                                                                          ) +
                                                                          "\n                    "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs12: "",
                                                                    "pt-2": ""
                                                                  }
                                                                },
                                                                [
                                                                  !_vm.loading
                                                                    ? _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs: {
                                                                            disabled:
                                                                              !_vm.getUser ||
                                                                              _vm.insufficientMiles,
                                                                            color:
                                                                              "primary",
                                                                            large:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click:
                                                                              _vm.participate
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Participar"
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "layout row wrap pt-4"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  staticClass:
                                                                                    "size16 flex md1 xs2 primary",
                                                                                  attrs: {
                                                                                    small:
                                                                                      "",
                                                                                    color:
                                                                                      "white"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "local_airport"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "v-progress-linear",
                                                                                {
                                                                                  staticClass:
                                                                                    "flex md11 xs10",
                                                                                  staticStyle: {
                                                                                    margin:
                                                                                      "0px"
                                                                                  },
                                                                                  attrs: {
                                                                                    color:
                                                                                      "primary",
                                                                                    height:
                                                                                      "25",
                                                                                    value:
                                                                                      _vm.progressUpload
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "p",
                                                                                    {
                                                                                      staticClass:
                                                                                        "white--text font-weight-black mb-0",
                                                                                      staticStyle: {
                                                                                        "padding-top":
                                                                                          "2px !important"
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                              " +
                                                                                          _vm._s(
                                                                                            _vm.progressUpload
                                                                                          ) +
                                                                                          "%\n                            "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "p",
                                                                            {
                                                                              staticClass:
                                                                                "pt-2 primary--text"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                          " +
                                                                                  _vm._s(
                                                                                    _vm.fileUpload ||
                                                                                      !_vm
                                                                                        .record
                                                                                        .image
                                                                                      ? "Enviando tu registro de participación..."
                                                                                      : "Subiendo archivo adjunto..."
                                                                                  ) +
                                                                                  "\n                        "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass: "registrate container pa-0"
                                    },
                                    [
                                      _c("v-flex", { attrs: { primary: "" } }, [
                                        _c(
                                          "h2",
                                          {
                                            staticClass:
                                              "display-1 white--text font-weight-thin py-4"
                                          },
                                          [_vm._v("¡Regístrate y participa!")]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass:
                                            "white border-content shadow-lg",
                                          attrs: {
                                            xs12: "",
                                            "py-5": "",
                                            "pa-2": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "h3",
                                            { staticClass: "subheading pb-4" },
                                            [
                                              _vm._v(
                                                "Regístrate para que puedas inscribirte en esta y otras campañas más"
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to:
                                                  "/register?redirect_to=/" +
                                                  _vm.getCampaign.brand.id +
                                                  "/" +
                                                  _vm.getCampaign.id
                                              }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    large: ""
                                                  }
                                                },
                                                [_vm._v("Regístrate")]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "pt-4 body-1" },
                                            [
                                              _vm._v(
                                                "\n                ¿Ya tienes una cuenta?\n                "
                                              ),
                                              _c(
                                                "router-link",
                                                { attrs: { to: "/login" } },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-bold"
                                                    },
                                                    [_vm._v("Inicia sesión")]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                            ],
                            1
                          ),
                      _vm._v(" "),
                      _vm.getUser
                        ? _c(
                            "v-dialog",
                            {
                              attrs: {
                                "max-width": "500",
                                persistent: "",
                                "data-app": ""
                              },
                              model: {
                                value: _vm.modalCompetitor,
                                callback: function($$v) {
                                  _vm.modalCompetitor = $$v
                                },
                                expression: "modalCompetitor"
                              }
                            },
                            [
                              _c(
                                "v-card",
                                { staticClass: "pb-2" },
                                [
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass:
                                        "subheading xs-text-center white"
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "", wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "text-xs-center mb-0 pt-3 px-3"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    ¡Felicidades\n                    "
                                                  ),
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(_vm.getUser.name)
                                                    )
                                                  ]),
                                                  _vm._v(
                                                    " acabas de inscribirte en esta campaña con éxito!\n                  "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.getCampaign.type ===
                                              "tokenized"
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-xs-center mb-0 pb-3"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    Tu código promocional en esta campaña es\n                    "
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "primary--text font-weight-bold"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(_vm.token)
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm.getCampaign.type ===
                                                  "only_terms"
                                                ? _c("p", [
                                                    _vm._v(
                                                      "\n                    Gracias por participar en la campaña\n                  "
                                                    )
                                                  ])
                                                : _c("p", [
                                                    _vm._v(
                                                      "\n                    Validaremos la información brindada y podrás acumular\n                    "
                                                    ),
                                                    _c("strong", [
                                                      _vm.miles > 0
                                                        ? _c("span", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.miles
                                                                ) +
                                                                " "
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(
                                                        "millas\n                    "
                                                      )
                                                    ]),
                                                    _vm._v(
                                                      "\n                    en tu cuenta\n                  "
                                                    )
                                                  ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-card-actions",
                                    { staticClass: "pt-0" },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { "justify-center": "" } },
                                        [
                                          _c(
                                            "router-link",
                                            { attrs: { to: "/profile" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "primary darken-1"
                                                  }
                                                },
                                                [_vm._v("Aceptar")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-dialog",
                        {
                          attrs: { "max-width": "500", "data-app": "" },
                          model: {
                            value: _vm.modalError,
                            callback: function($$v) {
                              _vm.modalError = $$v
                            },
                            expression: "modalError"
                          }
                        },
                        [
                          _c(
                            "v-card",
                            { staticClass: "pb-2" },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "subheading xs-text-center" },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c("v-flex", { attrs: { xs12: "" } }, [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "text-xs-center mb-0"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.messageModalError)
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card-actions",
                                { staticClass: "pt-0" },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { "justify-center": "" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "primary darken-1" },
                                          on: {
                                            click: function($event) {
                                              _vm.modalError = false
                                            }
                                          }
                                        },
                                        [_vm._v("Aceptar")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-layout",
            {
              attrs: {
                row: "",
                "fill-height": "",
                "align-center": "",
                "xs-minvh90": ""
              }
            },
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/landing/loading.gif"),
                    height: "100"
                  }
                })
              ])
            ],
            1
          ),
      _vm._v(" "),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }