var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-layout",
        { attrs: { white: "" } },
        [
          _c(
            "v-container",
            [
              _c("v-card", [
                _c(
                  "div",
                  { staticClass: "pa-5 content_term" },
                  [
                    _c(
                      "h1",
                      { staticClass: "headline mb-4 pb-2 font-weight-black" },
                      [_vm._v("Política de Privacidad")]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-xs-left pb-2" }, [
                      _vm._v(
                        "\n            La presente Política de Privacidad establece la base y condiciones para el procesamiento de sus Datos\n            Personales por el uso del proyecto y sitio web GANAPASS (el “Proyecto”, el “Sitio Web”, la “Aplicación” o\n            “GANAPASS”), de conformidad con lo establecido en la Ley N° 29733 – “Ley de Protección de Datos\n            Personales”, y su Reglamento, Decreto Supremo N° 003-2013-JUS y las demás disposiciones complementarias.\n            "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n            El responsable por el tratamiento de sus datos personales será UNA SOLUTIONS S.A.C., con RUC No.\n            20554157905, y domicilio en y domicilio en Avenida Ricardo Palma 248, Oficina 801, Miraflores, Lima, (“UNA\n            SOLUTIONS”, o “la empresa responsable”).\n\n            "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n            Con la aceptación de la presente Política de Privacidad, la empresa responsable obtiene de cada usuario su\n            consentimiento libre, expreso, previo informado e inequívoco para el tratamiento de sus datos personales,\n            de conformidad con las condiciones detalladas en el presente documento. Dicho consentimiento estará\n            vigente hasta que el usuario revoque la autorización otorgada.\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "h2",
                      {
                        staticClass:
                          "subheading text-xs-left font-weight-black mb-2 underline"
                      },
                      [
                        _vm._v(
                          "\n            Datos personales recopilados y utilizados\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-xs-left pb-2" }, [
                      _vm._v(
                        "\n            A través del proceso de registro de cada usuario en el Sitio Web, UNA SOLUTIONS accederá a los siguientes\n            Datos Personales:\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-list",
                      { attrs: { subheader: "", "three-line": "" } },
                      [
                        _c(
                          "v-list-tile",
                          [
                            _c("v-list-tile-action", [
                              _c("p", [_vm._v("1.-")])
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-list-tile-content",
                              [
                                _c("v-list-tile-sub-title", [
                                  _vm._v(
                                    "Nombres, apellidos, fecha de nacimiento, número de documento de identidad, correo electrónico y\n                  teléfono, los cuales son cedidos durante el proceso de registro de usuario."
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-tile",
                          [
                            _c("v-list-tile-action", [
                              _c("p", [_vm._v("2.-")])
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-list-tile-content",
                              [
                                _c("v-list-tile-sub-title", [
                                  _vm._v(
                                    "GANAPASS también recibe y registra información que automáticamente nos envía el navegador que\n                  utiliza, incluyendo su Dirección IP y las cookies del Sitio Web.\n                "
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-tile",
                          [
                            _c("v-list-tile-action", [
                              _c("p", [_vm._v("3.-")])
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-list-tile-content",
                              [
                                _c("v-list-tile-sub-title", [
                                  _vm._v(
                                    "Información sobre sus actividades en la web (páginas visitadas, búsquedas realizadas, historial de\n                  participación, entre otros).\n                "
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-xs-left pb-2" }, [
                      _vm._v(
                        "\n            El tratamiento de Los Datos Personales se realizará cumpliendo los principios y disposiciones establecidas\n            en la Ley y su Reglamento, y constituyen un aspecto esencial para la ejecución de los Términos y\n            Condiciones\n            "
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "primary--text font-weight-black",
                          attrs: { href: "/terms" }
                        },
                        [_vm._v("https://ganapass.com/terms. ")]
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-xs-left pb-2" }, [
                      _vm._v(
                        "\n            Los Datos Personales del usuario podrán ser utilizados por la empresa responsable con la siguiente\n            finalidad:\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-list",
                      { attrs: { subheader: "", "three-line": "" } },
                      [
                        _c(
                          "v-list-tile",
                          [
                            _c("v-list-tile-action", [_c("p", [_vm._v("-")])]),
                            _vm._v(" "),
                            _c(
                              "v-list-tile-content",
                              [
                                _c("v-list-tile-sub-title", [
                                  _vm._v(
                                    "Personalizar contenido, enviar publicidad, noticias e información acerca de los servicios de\n                  GANAPASS, así como de las Promociones Comerciales que publiciten los Organizadores, las cuales\n                  pudiesen ser de su interés. Dicha información podrá ser enviada al Usuario por correos electrónicos,\n                  mensajes de texto, notificaciones push y/o llamadas telefónicas.\n                "
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-tile",
                          [
                            _c("v-list-tile-action", [_c("p", [_vm._v("-")])]),
                            _vm._v(" "),
                            _c(
                              "v-list-tile-content",
                              [
                                _c("v-list-tile-sub-title", [
                                  _vm._v(
                                    "Facilitar su participación en las Promociones Comerciales que son publicitadas por los\n                  Organizadores en GANAPASS. En tal sentido, podrá enviar notificaciones y/o mensajes al Usuario con\n                  el fin de informarle sobre el estado de su participación en las Promociones Comerciales.\n                "
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-tile",
                          [
                            _c("v-list-tile-action", [_c("p", [_vm._v("-")])]),
                            _vm._v(" "),
                            _c(
                              "v-list-tile-content",
                              [
                                _c("v-list-tile-sub-title", [
                                  _vm._v(
                                    "Mejora de los servicios y procedimientos de GANAPASS y UNA SOLUTIONS.\n                "
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-xs-left pb-2" }, [
                      _vm._v(
                        "\n            En cualquier momento, los Usuarios de GANAPASS podrán solicitar la baja de su cuenta y la eliminación de\n            su información de las bases de datos de GANAPASS.\n            "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n            El usuario acepta que las empresas responsables podrán acceder, recopilar, registrar, organizar,\n            almacenar, conservar, elaborar, modificar, bloquear, suprimir, extraer, consultar, utilizar, transferir o\n            procesar sus datos personales de cualquier otra forma prevista por la Ley.\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "h2",
                      {
                        staticClass:
                          "subheading text-xs-left font-weight-black mb-2 underline"
                      },
                      [
                        _vm._v(
                          "\n            Compartir Datos Personales e información del Usuario\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-xs-left pb-2" }, [
                      _vm._v(
                        "\n            UNA SOLUTIONS no vende ni comercializa información personal de sus usuarios. Sin perjuicio de ello, la\n            empresa responsable compartirá los datos personales que nos proporcione el Usuario con terceros en los\n            siguientes supuestos:\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-list",
                      { attrs: { subheader: "", "three-line": "" } },
                      [
                        _c(
                          "v-list-tile",
                          [
                            _c("v-list-tile-action", [
                              _c("p", [_vm._v("1.-")])
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-list-tile-content",
                              [
                                _c("v-list-tile-sub-title", [
                                  _vm._v(
                                    "Cuando resulte necesario para la ejecución de las Promociones Comerciales en las que el Usuario se\n                  hubiese inscrito como participante. En tal sentido, UNA SOLUTIONS podrá compartir los datos\n                  personales del Usuario con las empresas que actúen como Organizador de una Promoción Comercial, a\n                  efectos de hacer posible la participación del Usuario y su contacto como potencial ganador.\n                "
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-tile",
                          [
                            _c("v-list-tile-action", [
                              _c("p", [_vm._v("2.-")])
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-list-tile-content",
                              [
                                _c("v-list-tile-sub-title", [
                                  _vm._v(
                                    "Cuando resulte necesario para facilitar y permitir la entrega de los premios a los que hubiese\n                  accedido el Usuario en virtud de una Promoción Comercial. Entre ellos, UNA SOLUTIONS podrá compartir\n                  los datos personales del Usuario con LATAM AIRLINES GROUP S.A. SUCURSAL PERÚ, para facilitar la\n                  creación de su membresía como socio del Programa LATAM Pass.\n                "
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-tile",
                          [
                            _c("v-list-tile-action", [
                              _c("p", [_vm._v("3.-")])
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-list-tile-content",
                              [
                                _c("v-list-tile-sub-title", [
                                  _vm._v(
                                    "Cuando resulte necesario compartir esta información con empresas que sean contratadas por UNA\n                  SOLUTIONS y que nos sirven para proporcionarle un servicio personalizado (tales empresas no podrán\n                  usar sus datos para ningún fin distinto del especificado por UNA SOLUTIONS).\n                "
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-tile",
                          [
                            _c("v-list-tile-action", [
                              _c("p", [_vm._v("4.-")])
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-list-tile-content",
                              [
                                _c("v-list-tile-sub-title", [
                                  _vm._v(
                                    "Cuando nos sean requeridos por autoridades, sean judiciales, administrativas, policiales, o de\n                  cualquier otra naturaleza.\n                "
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "h2",
                      {
                        staticClass:
                          "subheading text-xs-left font-weight-black mb-2 underline"
                      },
                      [_vm._v("\n            Plazo\n          ")]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-xs-left pb-2" }, [
                      _vm._v(
                        "\n            UNA SOLUTIONS conservará y tratará los datos hasta el usuario titular revoque su consentimiento.\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "h2",
                      {
                        staticClass:
                          "subheading text-xs-left font-weight-black mb-2 underline"
                      },
                      [
                        _vm._v(
                          "\n            Derechos del titular de los Datos Personales\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-xs-left pb-2" }, [
                      _vm._v(
                        "\n            En cualquier momento, el usuario podrá ejercer sus derechos de información, acceso, actualización,\n            inclusión, rectificación, supresión y oposición de sus datos personales. Para tal finalidad, el titular de\n            los datos personales deberá solicitarlo a través de un correo electrónico a la dirección\n            "
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "font-weight-black",
                          attrs: {
                            href: "mailto:legal-ganapass@unasolutions.com "
                          }
                        },
                        [_vm._v("legal-ganapass@unasolutions.com")]
                      ),
                      _vm._v(
                        ", y adjuntar una fotocopia de su documento de identidad.\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "h2",
                      {
                        staticClass:
                          "subheading text-xs-left font-weight-black mb-2 underline"
                      },
                      [
                        _vm._v(
                          "\n            Medidas de seguridad y confidencialidad\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-xs-left pb-2" }, [
                      _vm._v(
                        "\n            UNA SOLUTIONS cumple con las medidas de seguridad y confidencialidad requeridas por la normativa de\n            aplicación para evitar la alteración, pérdida, tratamiento o acceso no autorizado a sus datos personales.\n            "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n            La empresa responsable se reserva el derecho de modificar su Política de Privacidad en cualquier momento,\n            los cuales deberán ser informados oportunamente por medio de su correo electrónico. En caso el titular no\n            acepte la actualización de las Políticas de Privacidad, no podrá seguir utilizando los servicios puestos a\n            su disposición mediante el Sitio Web.\n          "
                      )
                    ])
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }