<template>
  <v-app>
    <Navbar />
    <v-container v-if="getBrand && getCampaigns" pa-0>
      <v-layout class="mt-60">
        <v-container class="pa-2 pb-0 border_title hidden-md-and-down">
          <v-layout align-end justify-space-between row fill-height>
            <v-flex xs12 md6 pa-2>
              <h5 class="display-1 text-xs-left  primary--text font-weight-thin">Campaña</h5>
            </v-flex>
            <v-flex xs12 md6 class="logo-campaing">
              <v-img :src="getBrand.photo" width="100"></v-img>
            </v-flex>
          </v-layout>
        </v-container>
        <v-container class="pa-2 pb-0 hidden-lg-and-up">
          <v-flex xs12 md6 pa-2 border_title>
            <h5 class="title text-xs-left  primary--text font-weight-thin">Campaña</h5>
          </v-flex>
          <v-layout align-center justify-center>
            <div class="pa-3">
              <v-img :src="getBrand.photo" width="100"></v-img>
            </div>
          </v-layout>
        </v-container>
      </v-layout>
      <!-- <CardCampaign/> -->
      <v-layout row container>
        <v-flex xs12>
          <v-layout row wrap justify-center>
            <v-flex v-for="(campaign, i) in getCampaigns" :key="i" xs12 sm6 md4 pa-3 d-flex style="position: relative;">
              <CardCampaign :campaign="campaign" />
            </v-flex>
          </v-layout>
          <v-layout v-if="getCampaigns.length == 0">
            <v-flex xs12>
              Aún no hay campañas en las que puedas participar
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <v-layout v-else row fill-height align-center xs-minvh90>
      <v-flex xs12>
        <img :src="require('@/assets/img/landing/loading.gif')" height="100" />
      </v-flex>
    </v-layout>
    <Footer />
  </v-app>
</template>
<script>
import Navbar from "@/components/inc/Navbar";
import Footer from "@/components/inc/Footer";
import CardCampaign from "@/components/cards/Campaign";

import { mapGetters, mapActions } from "vuex";

import formatCurrency from "@/utils/format_currency";

export default {
  name: "Brand",
  components: {
    Navbar,
    Footer,
    CardCampaign,
  },
  data() {
    return {
      brand: {
        id: this.$route.params.brand_id,
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getBrand", "getCampaigns"]),
  },
  watch: {
    async getBrand(brand) {
      if (brand) {
        await this.getCampaignsByBrand({ brand_id: this.brand.id });
      }
    },
  },
  async created() {
    await this.getBrandById({ id: this.brand.id });
  },
  methods: {
    ...mapActions(["getBrandById", "getCampaignsByBrand"]),
    formatCurrency(amount) {
      return formatCurrency(amount);
    },
  },
};
</script>
<style scoped>
.border_title {
  border-bottom: 1px solid #1b0088;
}
.logo-campaing {
  text-align: -webkit-right !important;
}
</style>
