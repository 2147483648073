<template>
  <v-app>
    <!-- Banner -->
    <v-layout white>
      <v-container>
        <v-card>
          <div class="pa-5 content_term">
            <h1 class="headline mb-4 pb-2 font-weight-black">Términos y Condiciones de <b> GANAPASS </b></h1>
            <h2 class="subheading text-xs-left font-weight-black mb-2 underline">Condiciones generales</h2>
            <p class="text-xs-left pb-2">
              El presente documento establece los Términos y Condiciones de uso (“los Términos y Condiciones”) del
              proyecto y sitio web
              <a href="www.ganapass.com" class="primary--text font-weight-bold">www.ganapass.com</a> (la “Aplicación”,
              el “Sitio Web”, o “<span class="font-weight-bold">GANAPASS</span> ”). Los Términos y Condiciones
              constituyen un acuerdo, que será plenamente válido entre
              <span class="font-weight-bold">UNA SOLUTIONS S.A.C.</span>, con RUC No. 20554157905, y domicilio en
              Avenida Ricardo Palma 248, Oficina 801, Miraflores, Lima, (en adelante, “UNA SOLUTIONS”) y los usuarios
              visitantes que acceden al Sitio Web (en adelante, el “Usuario” o los “Usuarios”).
              <br />
              <br />
              Usted reconoce que la aceptación de los presentes Términos y Condiciones, así como de nuestra Política de
              Privacidad
              <a href="/privacy-policy" class="font-weight-bold primary--text"> https://ganapass.com/privacy-policy </a
              >, constituyen requisitos obligatorios para que los Usuarios puedan hacer uso de cualquiera de las
              características y funciones que ofrece el Sitio Web, descritos a continuación
              <br />
              <br />
              Al acceder y registrarse en el Sitio Web, o al utilizar cualquiera de sus servicios y funciones, usted
              declara conocer y aceptar en su totalidad el contenido de los presentes Términos y Condiciones, así como
              de nuestra Política de Privacidad.
            </p>
            <h2 class="subheading text-xs-left font-weight-black mb-2 underline">Sobre el Sitio Web</h2>
            <p class="text-xs-left pb-2">
              La Aplicación es una plataforma virtual administrada por UNA SOLUTIONS, la cual publicita y pone a
              disposición de sus Usuarios visitantes un catálogo que les permite visualizar y acceder a distintas
              promociones comerciales (en adelante, las “Promociones Comerciales”), las cuales son organizadas y
              publicadas por terceros proveedores (en adelante, los “Organizadores”).
              <br />
              <br />
              En este sentido, la plataforma GANAPASS constituye una plataforma de intermediación comercial que ofrece a
              los usuarios visitantes la posibilidad de buscar, visualizar, y participar en distintas Promociones
              Comerciales, las cuales se regirán por las bases, términos y condiciones que previamente haya definido su
              respectivo Organizador.
            </p>
            <h2 class="subheading text-xs-left font-weight-black mb-2 underline">Sobre los Organizadores</h2>
            <p class="text-xs-left pb-2">
              Los Organizadores son personas jurídicas o naturales independientes de UNA SOLUTIONS, quienes definen por
              cuenta propia las bases, premios, mecánicas, plazos, términos y demás condiciones que regirán las
              Promociones Comerciales que publiciten a través de GANAPASS. Los Organizadores podrán ser plenamente
              identificados por los Usuarios en las condiciones y publicaciones de sus Promociones Comerciales.
              <br />
              <br />
              Los Organizadores son los únicos y exclusivos responsables de la correcta ejecución de las Promociones
              Comerciales que hayan publicado en el Sitio Web, así como del cumplimiento de cualquiera de las
              obligaciones que hubiesen asumido frente a los Usuarios, incluyendo -pero no limitándose- a la entrega
              oportuna de los premios ofrecidos.
              <br />
              <br />
              UNA SOLUTIONS no es responsable por el cumplimiento de las obligaciones asumidas por los Organizadores ni
              por el contenido de sus publicaciones. Cada Organizador será el único responsable por los daños o
              perjuicios que pudiese causar al Usuario dentro del ámbito de la ejecución de sus Promociones Comerciales.
            </p>
            <h2 class="subheading text-xs-left font-weight-black mb-2 underline">Uso del sitio web</h2>
            <p class="text-xs-left pb-2">
              Para poder hacer uso del Sitio Web, cada Usuario deberá:
            </p>
            <v-list subheader three-line>
              <v-list-tile>
                <v-list-tile-action>
                  <p>1.-</p>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-sub-title
                    >Completar un proceso de registro, donde se le solicitará ingresar sus datos personales (nombres,
                    apellidos, documento de identidad, fecha de nacimiento, número de teléfono y correo electrónico),
                    aceptando los Términos y Condiciones y la Política de Privacidad de GANAPASS. Una vez concluido el
                    proceso de registro, el Usuario podrá iniciar sesión haciendo uso de su usuario y
                    contraseña.</v-list-tile-sub-title
                  >
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-action>
                  <p>2.-</p>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-sub-title
                    >Luego de iniciar sesión, el usuario deberá seleccionar la publicación de su interés, donde podrá
                    acceder a la información y las bases de la Promoción Comercial publicadas por cada Organizador. Así
                    como también, recibirá comunicaciones de las campañas y promociones gestionadas por GanaPass en las
                    cuales también podrá participar.
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-action>
                  <p>3.-</p>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-sub-title
                    >Para participar de una Promoción Comercial, el Usuario deberá completar todos los campos y
                    presentar la información obligatoria que le fuese solicitada por el Organizador, incluyendo (según
                    cada caso) sus datos de contacto, el número de la boleta de compra, el importe de la compra
                    realizada, la foto de la boleta de compra, u otros que fuesen requeridos por el Organizador de la
                    Promoción Comercial. Así como también, aprobar los términos y condiciones correspondientes a cada
                    campaña.
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
            <h2 class="subheading text-xs-left font-weight-bold mt-2 underline mb-2">Millas LATAM Pass</h2>
            <p class="text-xs-left pb-4 mb-0">
              Algunas Promociones Comerciales publicadas por los Organizadores en GANAPASS podrán ofrecer a los Usuarios
              -como premio- la entrega de Millas LATAM Pass (en adelante, las “Millas” o “Millas LATAM Pass”). Las
              millas LATAM Pass constituyen la unidad de medida del Programa LATAM Pass, administrado exclusivamente por
              <b>LATAM AIRLINES GROUP S.A. SUCURSAL PERÚ </b>(en adelante, “LATAM”). Para más información sobre el
              Programa LATAM Pass, usted puede acceder a la siguiente URL:
            </p>
            <a
              href="https://www.latam.com/es_pe/latam-pass/sobre-el-programa/terminos-y-condiciones/"
              class="pa-4 text-xs-left font-weight-black"
            >
              https://www.latam.com/es_pe/latam-pass/sobre-el-programa/terminos-y-condiciones/
            </a>
            <p class="text-xs-left pb-2 pt-4">
              En caso que el Usuario se inscriba a una Promoción Comercial donde el Organizador ofrezca Millas LATAM
              Pass, y fuese seleccionado como ganador, el Usuario deberá ser titular de una cuenta de socio LATAM Pass
              para que el organizador pueda hacer efectiva la entrega de Millas.
              <br />
              <br />
              En el supuesto que el Usuario seleccionado como ganador no contase con una cuenta de socio LATAM Pass, UNA
              SOLUTIONS y/o el Organizador podrán coordinar con LATAM la creación de una membresía como socio del
              Programa LATAM Pass, a fin de que el Organizador pueda hacer efectiva la entrega de sus Millas, aceptando
              los términos y condiciones del programa de Millas LATAM Pass mencionados anteriormente.
            </p>

            <h2 class="subheading text-xs-left font-weight-black mb-2 underline">Otras consideraciones</h2>
            <p class="text-xs-left pb-2">
              Al aceptar los Términos y Condiciones,el usuario declara ser mayor de edad de conformidad con las leyes
              peruanas vigentes, lo cual constituye un requisito para la participación en la promoción. Cualquier
              usuario que no cumpla con este requisito, será descalificado.
              <br />
              <br />
              En caso que UNA SOLUTIONS identifique un uso inadecuado del Sitio Web por parte de un usuario, o el uso de
              algún mecanismo que busque defraudar las mecánicas de participación establecidas en los Términos y
              Condiciones, UNA SOLUTIONS podrá suspender, descalificar y/o bloquear al usuario infractor de manera
              definitiva y restringirlo de acceder al premio en cualquier momento y etapa de la promoción, incluso
              después de la selección de ganadores. Esta situación será comunicada a dicho usuario.
              <br />
              <br />
              El Sitio Web puede contener hipervínculos o Links a otros sitios web, que no sean controlados, editados,
              ni tengan relación alguna con UNA SOLUTIONS, por lo cual la empresa no será responsable del contenido ni
              de la información contenida en ellos. La función de los links que se encuentran en el Sitio Web será
              meramente informativa y es proporcionada por terceros, siendo responsabilidad de cada Organizador el
              contenido de los Links que incluya en su publicación.
              <br />
              <br />
              Los usuarios que fuesen seleccionados como ganadores en una Promoción Comercial publicada en GANAPASS,
              autorizan a UNA SOLUTIONS y al Organizador para publicar sus datos en cualquier medio de comunicación, con
              fines relacionados a la promoción y ejecución de la Promoción Comercial.
              <br />
              <br />
              En caso exista una modificación de los presentes Términos y Condiciones o de la Política de Privacidad,
              UNA SOLUTIONS informará a los usuarios sobre dicho cambio. En caso ello ocurra, la continuidad de uso del
              servicio y el acceso al Sitio Web implicarán la aceptación y conformidad del usuario con cualquier
              modificación que pudiese haber surtido efecto.
              <br />
              El presente acuerdo se regirá por la legislación especial sobre la materia de la República del Perú.
              <br />
              Para todo lo relativo a cualquier controversia o desacuerdo que surja respecto del presente acuerdo entre
              UNA SOLUTIONS y el Usuario, ambas partes renuncian expresamente al fuero que por razón de su domicilio o
              por cualquier otro motivo pudiera corresponderle. En ese sentido, los Términos y Condiciones se regirán
              por el ordenamiento jurídico peruano y, particularmente, por el Código Civil. El usuario reconoce que
              cualquier desavenencia o desacuerdo con UNA SOLUTIONS será sometida a la jurisdicción de los jueces y
              tribunales de justicia de Lima Cercado.
              <br />
              <br />
              Las partes acuerdan que la invalidez o nulidad de cualquiera de las cláusulas del presente acuerdo no
              acarrea el mismo efecto para el resto de las cláusulas, entendiéndose la plena vigencia del acuerdo con
              independencia de la disposición que resulte invalidada o nula. Asimismo, la cláusula inválida o nula será
              limitada o eliminada en la medida mínima necesaria para que el acuerdo permanezca en plena vigencia y de
              obligado cumplimiento.
              <br />
              <br />
              En caso de cualquier duda o comentario sobre los Términos y Condiciones, usted podrá comunicarse con UNA
              SOLUTIONS por medio de
              <a href="mailto:legal-ganapass@unasolutions.com " class="font-weight-black"
                >legal-ganapass@unasolutions.com</a
              >
            </p>
          </div>
        </v-card>
      </v-container>
    </v-layout>
  </v-app>
</template>
<script>
export default {
  name: "Terms",
};
</script>
<style>
.v-list__tile {
  height: auto !important;
  min-height: 30px !important;
  padding: 8px;
}
@media (max-width: 768px) {
  .content_term {
    padding: 16px !important;
  }
}
</style>
