<template>
  <v-app>
    <Navbar />
    <v-layout v-if="getCategory" row wrap>
      <!-- Banner -->
      <v-flex xs12>
        <v-layout class="mt-60 banner-detail" :style="imgSection">
          <v-container>
            <v-flex xs12 md5>
              <v-layout row wrap pt-3 pb-0 px-2 text-sm-left>
                <v-flex xs12>
                  <v-layout row wrap text-sm-left>
                    <v-flex md-5 xs-12>
                      <v-img v-if="getCategory.logo" :src="getCategory.logo" width="140"></v-img>
                    </v-flex>
                  </v-layout>
                  <h2 class="white--text font-weight-black pb-3 display-1">{{ getCategory.name }}</h2>
                  <h2 class="white--text font-weight-black pb-2 subheading">{{ getCategory.description }}</h2>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-container>
        </v-layout>
      </v-flex>
      <v-flex xs12 py-4>
        <v-layout v-if="getCampaigns" row wrap>
          <v-container>
            <v-flex xs12>
              <v-layout row wrap justify-center>
                <v-flex
                  v-for="(campaign, i) in getCampaigns"
                  :key="i"
                  xs12
                  sm6
                  md4
                  pa-3
                  d-flex
                  style="position: relative;"
                >
                  <CardCampaign :campaign="campaign" />
                </v-flex>
              </v-layout>
              <v-layout v-if="getCampaigns.length == 0">
                <v-flex xs12>
                  Aún no hay campañas en las que puedas participar
                </v-flex>
              </v-layout>
            </v-flex>
          </v-container>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout v-else row fill-height align-center xs-minvh90>
      <v-flex xs12>
        <img :src="require('@/assets/img/landing/loading.gif')" height="100" />
      </v-flex>
    </v-layout>
    <Footer />
  </v-app>
</template>
<script>
import Navbar from "@/components/inc/Navbar";
import Footer from "@/components/inc/Footer";
import CardCampaign from "@/components/cards/Campaign";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "Category",
  components: {
    Navbar,
    Footer,
    CardCampaign,
  },
  data() {
    return {
      category: {
        id: this.$route.params.category_id,
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getCategory", "getCampaigns"]),
    imgSection: function() {
      return {
        background: "url(" + this.getCategory.banner + ")",
      };
    },
  },
  async created() {
    await this.getCategoryById({ id: this.category.id });
    await this.getCampaignsByCategory({ category_id: this.category.id });
  },
  methods: {
    ...mapActions(["getCategoryById", "getCampaignsByCategory"]),
  },
};
</script>
