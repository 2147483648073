<template>
  <v-app>
    <Navbar @goTo="goTo" />
    <div v-if="getCampaign">
      <!-- Banner -->
      <v-layout class="mt-60 banner-detail" :style="img_section_style">
        <v-container mt-0 pt-0>
          <v-flex xs12 md5>
            <v-layout row wrap pt-3 pb-0 px-2 text-sm-left>
              <v-flex xs12>
                <v-layout row wrap text-sm-left>
                  <v-flex md-5 xs-12>
                    <img
                      v-if="getCampaign.brand.photo"
                      class="img-brand-campaign"
                      :src="getCampaign.brand.photo"
                      height="40"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-container>
      </v-layout>
    </div>
    <v-container v-if="getCampaign">
      <v-layout row wrap justify-center>
        <!-- Participa -->
        <v-flex id="participate" md6 x12>
          <v-container pl-2>
            <v-flex xs12 text-sm-left>
              <h2 class="primary--text font-weight-black pb-3 display-1">{{ getCampaign.name }}</h2>
              <h2 class="primary--text font-weight-black pb-4 subheading" v-html="getCampaign.resume"></h2>
            </v-flex>
            <v-flex xs12>
              <v-layout row wrap text-sm-left>
                <v-flex v-if="getCampaign.caption_detail" xs12 sm12 pa-3 primary mb-4>
                  <v-layout row wrap justify-center>
                    <v-flex xs12 px-1 white--text subheading>
                      <v-content class="font-weight-thin" v-html="getCampaign.caption_detail"></v-content>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <span class="subheading" v-html="getCampaign.description"></span>
                </v-flex>
                <v-flex v-if="getCampaign.closing" xs12 mt-4>
                  <v-layout row wrap justify-center subheading>
                    <v-flex xs12 v-html="getCampaign.closing.text"></v-flex>
                    <v-flex v-if="getCampaign.closing.button" xs12 pt-2>
                      <a :href="getCampaign.closing.button.url" target="_blank">
                        <v-btn color="primary" class="mx-0">{{ getCampaign.closing.button.text }}</v-btn>
                      </a>
                    </v-flex>
                    <v-flex
                      v-if="getCampaign.closing.caption"
                      xs12
                      pt-3
                      class="caption"
                      v-html="getCampaign.closing.caption"
                    ></v-flex>
                  </v-layout>
                </v-flex>
                <v-flex v-if="getCampaign.terms_url" xs12 class="mt-5">
                  <a :href="getCampaign.terms_url" target="_blank" class="underline"
                    >Términos y condiciones de la campaña</a
                  >
                </v-flex>
                <v-flex v-if="getCampaign.privacy_policy_url" xs12 class="mt-1">
                  <a :href="getCampaign.privacy_policy_url" target="_blank" class="underline"
                    >Política de privacidad de la campaña</a
                  >
                </v-flex>
              </v-layout>
            </v-flex>
          </v-container>
        </v-flex>
        <!-- Formulario -->
        <v-flex md6 x12 class="form-campaing">
          <v-layout
            v-if="getCampaign.type == 'informative' && getCampaign.info_extra"
            white
            shadow-lg
            align-center
            justify-center
            row
            wrap
          >
            <v-flex xs12 pa-5 align-center>
              <h3 class="subheading mb-3">
                {{ getCampaign.info_extra.reference_text }}
              </h3>
              <a :href="getCampaign.info_extra.reference_button.url" target="_blank">
                <v-btn color="primary">{{ getCampaign.info_extra.reference_button.text }}</v-btn>
              </a>
            </v-flex>
          </v-layout>
          <v-layout v-else-if="!getCampaign.is_valid" white shadow-lg align-center justify-center row wrap>
            <v-flex primary xs12>
              <h2 class="display-1 white--text font-weight-thin py-4">¡Próximamente!</h2>
            </v-flex>
            <v-flex xs12 pa-5 align-center>
              <div class="py-4">
                <h3 class="subheading">Podrás participar en esta campaña a partir del:</h3>
                <h3 class="subtitle">
                  {{ formatDate(getCampaign.date_start) }} al
                  {{ formatDate(getCampaign.date_end) }}
                </h3>
              </div>
            </v-flex>
          </v-layout>
          <v-layout v-else-if="getCampaign.outmiles" white shadow-lg align-center justify-center row fill-height>
            <v-flex xs12 pa-3 my-5>
              <h3 class="subheading">
                <span class="primary--text font-weight-bold">Lo sentimos</span>, pero ya no podrás participar en esta
                campaña debido a que ya no hay millas disponibles.
              </h3>
            </v-flex>
          </v-layout>
          <v-content v-else style="position:relative">
            <v-flex v-if="getUser">
              <h2 class="display-1 primary white--text font-weight-thin py-4">¡Participa de la promoción!</h2>
              <v-form v-if="participation" ref="formParticipate" class="white shadow-lg border-content">
                <v-layout row wrap mx-4 mb-4 class="content-form">
                  <v-flex v-if="getCampaign.type !== 'tokenized' && getCampaign.type !== 'only_terms'" xs12>
                    <v-layout row wrap>
                      <v-flex xs12>
                        <v-text-field
                          v-model="user.full_name"
                          :rules="rules.required"
                          disabled
                          label="Nombres y Apellidos"
                          placeholder=" "
                          readonly
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          v-model="user.email"
                          :rules="rules.required"
                          disabled
                          label="Correo electrónico"
                          placeholder=" "
                          readonly
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          v-model="user.birthdate"
                          :rules="rules.required"
                          disabled
                          label="Fecha de Nacimiento"
                          placeholder=" "
                          mask="##-##-####"
                          readonly
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field
                          v-model="user.phone"
                          :rules="rules.required"
                          disabled
                          label="Teléfono"
                          placeholder=" "
                          readonly
                        ></v-text-field>
                      </v-flex>
                      <v-flex v-if="getCampaign.validate_only_dni" xs12>
                        <v-text-field
                          v-model="user.number_document"
                          :label="user.type_document"
                          :rules="rules.required"
                          disabled
                          placeholder=" "
                          readonly
                        ></v-text-field>
                      </v-flex>
                      <v-flex v-if="getCampaign.stores" xs12>
                        <v-select
                          v-model="record.store"
                          :items="getCampaign.stores"
                          item-text="name"
                          item-value="name"
                          label="Tienda en donde adquiriste los productos"
                          placeholder="Seleccione una tienda"
                          :rules="rules.required"
                        ></v-select>
                      </v-flex>
                      <v-flex v-if="getCampaign.custom_stores" xs12>
                        <v-select
                          v-model="record.custom_store"
                          :items="getCampaign.custom_stores"
                          item-text="name"
                          item-value="name"
                          label="Tienda en donde adquiriste los productos"
                          placeholder="Seleccione una tienda"
                          :rules="rules.required"
                        ></v-select>
                      </v-flex>
                      <v-flex xs12>
                        <v-menu v-model="menuVoucherDate" :close-on-content-click="true" max-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="record.voucher_date"
                              label="Fecha de boleta"
                              placeholder=" "
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-if="menuVoucherDate" v-model="record.voucher_date"></v-date-picker>
                        </v-menu>
                      </v-flex>
                      <v-flex v-if="!getCampaign.disabled_voucher" xs12>
                        <v-text-field
                          v-model="record.voucher"
                          :rules="rules.voucher"
                          :disabled="!getUser"
                          label="Número de Boleta/Factura"
                          placeholder=" "
                          hint="* No debe incluir espacios en blanco o caracteres especiales"
                        ></v-text-field>
                      </v-flex>
                      <v-flex v-if="!getCampaign.disabled_voucher_image" xs12 d-flex>
                        <v-text-field
                          v-model="imageName"
                          :disabled="!getUser"
                          :rules="rules.required"
                          label="Foto de Boleta/Factura"
                          placeholder="Subir una foto"
                          readonly
                          class="w100 pointer"
                          @click="pickFile"
                        ></v-text-field>
                        <div>
                          <v-btn icon color="primary" :disabled="!getUser" @click="pickFile">
                            <v-icon>photo</v-icon>
                          </v-btn>
                        </div>
                        <input
                          ref="imageVoucher"
                          type="file"
                          style="display: none"
                          accept="application/pdf, image/*"
                          @change="onFilePicked"
                        />
                      </v-flex>
                      <v-flex v-if="(getCampaign.custom_fields || []).includes('district_plaza_vea')" xs12>
                        <v-select
                          v-model="record.district_plaza_vea"
                          :items="[
                            'Tienda Online',
                            'Ancon',
                            'Ate',
                            'Barranco',
                            'Breña',
                            'Carabayllo',
                            'Chaclacayo',
                            'Chorrillos',
                            'Cieneguilla',
                            'Comas',
                            'El Agustino',
                            'Independencia',
                            'Jesus Maria',
                            'La Molina',
                            'La Victoria',
                            'Lima',
                            'Lince',
                            'Los Olivos',
                            'Lurigancho',
                            'Lurin',
                            'Magdalena del Mar',
                            'Miraflores',
                            'Pachacamac',
                            'Pucusana',
                            'Pueblo Libre',
                            'Puente Piedra',
                            'Punta Hermosa',
                            'Punta Negra',
                            'Rimac (solo urb. piedra liza)',
                            'rimac (excepto urb. pied. liza)',
                            'San Bartolo',
                            'san borja',
                            'San Isidro',
                            'San Juan de Lurigancho',
                            'San Juan de Miraflores',
                            'San Luis',
                            'San Martin de Porres',
                            'San Miguel',
                            'Santa Anita',
                            'Santa Rosa',
                            'Santiago de Surco',
                            'Surquillo',
                            'Villa el Salvador',
                            'Villa Maria del Triunfo',
                          ]"
                          label="Distrito de Tienda Plaza Vea"
                          placeholder="Seleccione distrito"
                          :rules="rules.required"
                        ></v-select>
                      </v-flex>
                      <v-flex
                        v-if="(getCampaign.custom_fields || []).includes('number_of_bottles')"
                        :class="getCampaign.ratio ? 'md8 xs7' : 'xs12'"
                      >
                        <v-select
                          v-model="record.number_of_bottles"
                          :items="['1', '2', '3']"
                          label="Cantidad de botellas compradas"
                          placeholder="Seleccione cantidad"
                          :rules="rules.required"
                        ></v-select>
                      </v-flex>
                      <v-flex v-if="!getCampaign.disabled_amount" :class="getCampaign.ratio ? 'md8 xs7' : 'xs12'">
                        <v-text-field
                          v-model="record.amount"
                          :rules="rules.amount"
                          :disabled="!getUser"
                          label="Monto total de la compra"
                          prefix="S/. "
                          placeholder=" "
                          :hint="
                            getCampaign.min_amount > 0
                              ? `Monto mínimo de ${formatCurrency(getCampaign.min_amount)}`
                              : ''
                          "
                        ></v-text-field>
                      </v-flex>
                      <v-flex
                        v-if="record.amount && getCampaign.redeem && getCampaign.redeem.amount > record.amount"
                        class="xs12"
                      >
                        <v-alert :value="true" color="warning" icon="warning" class="text-xs-left font-weight-bold">
                          {{ getCampaign.redeem.text }}
                        </v-alert>
                      </v-flex>
                      <v-flex v-if="getCampaign.ratio" md4 xs5>
                        <div class="border-primary border-round py-1" color="primary">
                          <h6 class="caption mb-0">Podrás ganar</h6>
                          <v-icon small color="primary" class="size16">local_airport</v-icon>
                          <strong class="size16">{{ record.miles }} millas</strong>
                        </div>
                      </v-flex>
                      <v-flex text-xs-left py-2 pb-3 caption
                        >* La información brindada en este formulario será validada, previo a la acreditación de
                        millas.</v-flex
                      >
                      <v-flex xs12>
                        <v-alert :value="insufficientMiles" color="error" icon="warning" outline>
                          Lo sentimos, pero no se le puede asignar esa cantidad de millas a su cuenta debido al límite
                          de millas para esta campaña.
                        </v-alert>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout v-if="participate_record" row wrap>
                      <v-flex v-if="token" xs12 subheading>
                        Tu código promocional en esta campaña es
                        <span class="primary--text font-weight-bold">{{ token }}</span>
                      </v-flex>
                      <v-flex v-if="getCampaign.show_ranking" xs12>
                        <v-layout row wrap>
                          <v-flex xs12>
                            <h3 class="title py-4 font-weight-black">Ranking de participantes</h3>
                          </v-flex>
                          <v-flex v-if="records" xs12>
                            <v-data-table :headers="headers" :items="records" :hide-actions="true">
                              <template v-slot:items="props">
                                <td :class="props.item.classes">
                                  {{ props.item.position + 1 }}
                                </td>
                                <td :class="props.item.classes">
                                  {{ props.item.user_id === getUser.uid ? props.item.user.full_name : "Anonymous" }}
                                </td>
                                <td :class="props.item.classes">
                                  {{ formatCurrency(props.item.amount) }}
                                </td>
                              </template>
                            </v-data-table>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex v-else xs12 subheading>
                        Gracias por participar en la campaña
                      </v-flex>
                    </v-layout>
                    <v-layout v-else row wrap>
                      <v-flex xs12 d-flex>
                        <v-checkbox
                          v-model="record.terms"
                          color="primary"
                          class="mt-1"
                          :disabled="!getUser"
                          :rules="rules.required"
                        ></v-checkbox>
                        <p class="terms">
                          Acepto los
                          <a :href="getCampaign.terms_url" target="_blank" class="underline">
                            términos y condiciones
                          </a>
                          de la campaña
                          <span v-if="getCampaign.privacy_policy_url">
                            y la
                            <a :href="getCampaign.privacy_policy_url" target="_blank" class="underline"
                              >política de privacidad</a
                            >
                          </span>
                          de {{ getCampaign.brand.name }}
                        </p>
                      </v-flex>
                      <v-flex v-if="(getCampaign.custom_fields || []).includes('newsletter')" xs12 d-flex>
                        <v-checkbox
                          v-model="record.newsletter"
                          color="primary"
                          class="mt-1"
                          :disabled="!getUser"
                        ></v-checkbox>
                        <p class="terms">
                          Acepto recibir noticias e información promocional de {{ getCampaign.brand.name }}.
                        </p>
                      </v-flex>
                      <v-alert v-if="responseMessage" :value="true" color="error" icon="warning" outline>
                        {{ responseMessage }}
                      </v-alert>
                      <v-flex xs12 pt-2>
                        <v-btn
                          v-if="!loading"
                          :disabled="!getUser || insufficientMiles"
                          color="primary"
                          large
                          @click="participate"
                          >Participar</v-btn
                        >
                        <div v-else>
                          <div class="layout row wrap pt-4">
                            <v-icon small color="white" class="size16 flex md1 xs2 primary">local_airport</v-icon>
                            <v-progress-linear
                              color="primary"
                              height="25"
                              :value="progressUpload"
                              class="flex md11 xs10"
                              style="margin:0px"
                            >
                              <p class="white--text font-weight-black mb-0" style="padding-top: 2px !important;">
                                {{ progressUpload }}%
                              </p>
                            </v-progress-linear>
                          </div>
                          <p class="pt-2 primary--text">
                            {{
                              fileUpload || !record.image
                                ? "Enviando tu registro de participación..."
                                : "Subiendo archivo adjunto..."
                            }}
                          </p>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-flex>
            <div v-else class="registrate container pa-0">
              <v-flex primary>
                <h2 class="display-1 white--text font-weight-thin py-4">¡Regístrate y participa!</h2>
              </v-flex>
              <v-flex xs12 py-5 pa-2 class="white border-content shadow-lg">
                <h3 class="subheading pb-4">Regístrate para que puedas inscribirte en esta y otras campañas más</h3>
                <router-link :to="`/register?redirect_to=/${getCampaign.brand.id}/${getCampaign.id}`">
                  <v-btn color="primary" large>Regístrate</v-btn>
                </router-link>
                <div class="pt-4 body-1">
                  ¿Ya tienes una cuenta?
                  <router-link :to="'/login'">
                    <span class="font-weight-bold">Inicia sesión</span>
                  </router-link>
                </div>
              </v-flex>
            </div>
          </v-content>
          <!-- Modal competitor -->
          <v-dialog v-if="getUser" v-model="modalCompetitor" max-width="500" persistent data-app>
            <v-card class="pb-2">
              <v-card-text class="subheading xs-text-center white">
                <v-layout row wrap>
                  <v-flex xs12>
                    <p class="text-xs-center mb-0 pt-3 px-3">
                      ¡Felicidades
                      <strong>{{ getUser.name }}</strong> acabas de inscribirte en esta campaña con éxito!
                    </p>
                    <p v-if="getCampaign.type === 'tokenized'" class="text-xs-center mb-0 pb-3">
                      Tu código promocional en esta campaña es
                      <span class="primary--text font-weight-bold">{{ token }}</span>
                    </p>
                    <p v-else-if="getCampaign.type === 'only_terms'">
                      Gracias por participar en la campaña
                    </p>
                    <p v-else>
                      Validaremos la información brindada y podrás acumular
                      <strong>
                        <span v-if="miles > 0"> {{ miles }} </span>millas
                      </strong>
                      en tu cuenta
                    </p>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-layout justify-center>
                  <router-link to="/profile">
                    <v-btn color="primary darken-1">Aceptar</v-btn>
                  </router-link>
                </v-layout>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Modal error -->
          <v-dialog v-model="modalError" max-width="500" data-app>
            <v-card class="pb-2">
              <v-card-text class="subheading xs-text-center">
                <v-layout row wrap>
                  <v-flex xs12>
                    <p class="text-xs-center mb-0">{{ messageModalError }}</p>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-layout justify-center>
                  <v-btn color="primary darken-1" @click="modalError = false">Aceptar</v-btn>
                </v-layout>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-flex>
      </v-layout>
    </v-container>
    <v-layout v-else row fill-height align-center xs-minvh90>
      <v-flex xs12>
        <img :src="require('@/assets/img/landing/loading.gif')" height="100" />
      </v-flex>
    </v-layout>
    <Footer />
  </v-app>
</template>
<script>
import Navbar from "@/components/inc/Navbar";
import Footer from "@/components/inc/Footer";

import { mapGetters, mapActions } from "vuex";
import dateFormat from "dateformat";

import calculateMiles from "@/utils/calculate_miles";
import formatCurrency from "@/utils/format_currency";
import { storage } from "@/config/firebase";

export default {
  name: "Campaign",
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      headers: [
        {
          text: "Posición",
          align: "center",
          sortable: false,
          value: "position",
        },
        {
          text: "Nombre",
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: "Total de Compra",
          align: "center",
          sortable: false,
          value: "amount",
        },
      ],
      fileUpload: false,
      progressUpload: 0,
      brand: {
        id: this.$route.params.brand_id,
      },
      campaign: {
        id: this.$route.params.campaign_id,
      },
      user: {
        uid: "",
        full_name: "",
        email: "",
        birthdate: "",
        phone: "",
        type_document: "",
        number_document: "",
      },
      record: {
        voucher: "",
        store: "",
        image: "",
        amount: "",
        miles: 0,
        terms: false,
      },
      rules: {
        required: [(v) => !!v || "Campo requerido"],
        amount: [
          (v) => !!v || "Campo requerido",
          (v) => /^-?(?:0|0\.\d*|[1-9]\d*\.?\d*)$/.test(v) || "Campo inválido",
          (v) => parseFloat(v) > 0 || "Monto inválido",
          (v) =>
            parseFloat(v) >= this.getCampaign.min_amount ||
            `Cantidad insuficiente para participar. Monto mínimo de ${formatCurrency(this.getCampaign.min_amount)}`,
        ],
        voucher: [
          (v) => !!v || "Campo requerido",
          (v) => /^[a-z0-9ñ]+$/i.test(v) || "No se aceptan caracteres especiales ni espacios en blanco",
          (v) => this.prefixVoucherValid || `La boleta/factura ${v} es inválida`,
          (v) => this.voucherValid || `La boleta ${v} ya fue registrada`,
        ],
      },
      loading: false,
      modalCompetitor: false,
      modalError: false,
      messageModalError: "",
      insufficientMiles: false,
      miles: 0,
      token: null,
      participate_record: null,
      participation: false,
      imageName: "",
      imageFile: "",
      imageUrl: "",
      voucherValid: true,
      prefixVoucherValid: true,
      menuVoucherDate: false,
      records: null,
      responseMessage: null,
    };
  },
  computed: {
    ...mapGetters(["getUser", "getCampaign", "getDocumentTypes"]),
    img_section_style: function() {
      return {
        background: "url(" + this.getCampaign.banner + ")",
        // background: "url(" + require("../../assets/img/baner11.jpg") + ")",
      };
    },
  },
  watch: {
    getUser(user) {
      if (user) {
        this.user.uid = user.uid;
        this.user.name = user.name;
        this.user.lastname = user.lastname;
        this.user.full_name = `${user.name} ${user.lastname}`;
        this.user.type_document = this.getDocumentTypes[user.type_document].name;
        this.user.number_document = user.number_document;
        this.user.email = user.email;
        this.user.birthdate = user.birthdate;
        this.user.phone = user.phone;
        this.checkParticipation();
      }
    },
    "record.amount"(value) {
      if (this.getCampaign.ratio) {
        let miles = calculateMiles(value, this.getCampaign.ratio);
        this.insufficientMiles = miles > this.getCampaign.miles;
        this.record.miles = miles;
      }
    },
    "record.number_of_bottles"(value) {
      if (this.getCampaign.ratio) {
        let miles = calculateMiles(value, this.getCampaign.ratio);
        this.insufficientMiles = miles > this.getCampaign.miles;
        this.record.miles = miles;
      }
    },
    records(value) {
      let classes;
      for (var i = 0; i < value.length; i++) {
        classes = "";
        if (value[i].amount >= this.getCampaign.min_amount) classes += "selected";
        if (value[i].user_id === this.getUser.uid) classes += " me";
        value[i]["classes"] = classes;
      }
    },
  },
  async created() {
    await this.getCampaignById({ id: this.campaign.id });
  },
  methods: {
    ...mapActions([
      "getCampaignById",
      "saveRecord",
      "verifyRepeatVoucherRecord",
      "generateTokenRecord",
      "getRecord",
      "getCampaignRanking",
    ]),
    goTo(target) {
      this.$vuetify.goTo(target, {
        duration: 800,
        offset: 64,
        easing: "easeInOutCubic",
      });
    },
    pickFile() {
      this.$refs.imageVoucher.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName = files[0].name;
        if (this.imageName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.imageUrl = fr.result;
          this.record.image = files[0]; // this is an image file that can be sent to server...
        });
      } else {
        this.imageName = "";
        this.record.image = "";
        this.imageUrl = "";
      }
    },
    async participate() {
      try {
        this.loading = true;

        if (this.getCampaign.validate_only_dni && this.user.type_document !== "DNI")
          throw new Error("Está campaña está disponible para usuarios registrados con tipo de documento DNI");

        if (this.$refs.formParticipate.validate() && !this.insufficientMiles) {
          if (this.getCampaign.type === "tokenized") {
            let token = await this.generateTokenRecord();
            this.record.token = token;
            this.saveParticipeRecord();
          } else if (this.getCampaign.type === "only_terms") {
            this.saveParticipeRecord();
          } else if (this.validatePrefixVoucher()) {
            let isValidateVoucher = this.getCampaign.validate_voucher !== false;
            let voucherRepeat;

            if (isValidateVoucher)
              voucherRepeat = await this.verifyRepeatVoucherRecord({
                campaign_id: this.getCampaign.id,
                record_voucher: this.record.voucher,
              });

            if (!voucherRepeat) {
              if (this.record.image) {
                let filename = `${this.user.uid}-${new Date().getTime()}.${this.record.image.name.split(".").pop()}`;
                var uploadTask = storage
                  .ref()
                  .child(`records/${this.brand.id}/${this.getCampaign.id}/${filename}`)
                  .put(this.record.image);
                uploadTask.on(
                  "state_changed",
                  function(snapshot) {
                    this.progressUpload = parseFloat(
                      ((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(2),
                    );
                    if (this.progressUpload === 100) {
                      this.progressUpload = 90;
                      this.fileUpload = true;
                    }
                  }.bind(this),
                  function(error) {
                    this.messageModalError = "Hubo problemas al subir el archivo adjunto. ";
                    this.modalError = true;
                    this.sendException({ code: "UPLOAD-FILE-FAIL", message: error.code });
                    this.resetLoading();
                  },
                  async function() {
                    const fileUrl = await uploadTask.snapshot.ref.getDownloadURL();
                    this.saveParticipeRecord(fileUrl);
                  }.bind(this),
                );
              } else {
                this.saveParticipeRecord();
              }
            } else {
              this.resetLoading();
              this.sendException({ code: "VOUCHER-REPEAT", voucher: this.record.voucher });
              this.voucherValid = false;
              this.$refs.formParticipate.validate();
              this.voucherValid = true;
            }
          } else {
            this.resetLoading();
          }
        } else {
          this.resetLoading();
        }
      } catch (error) {
        this.messageModalError = error.message || "Hubo problemas con tu inscripción, por favor vuelve a intentarlo.";
        this.modalError = true;
        this.loading = false;
        console.error(error);
      }
    },
    async saveParticipeRecord(fileUrl) {
      this.progressUpload = 90;

      let response = await this.saveRecord({
        campaign: this.getCampaign,
        user: this.user,
        record: this.record,
        fileUrl: fileUrl ? fileUrl : "",
      });
      if (response.data) {
        this.progressUpload = 100;
        this.miles = response.data.miles;
        this.participate_record = response.data;
        this.token = this.participate_record.token;
        this.modalCompetitor = true;
        this.cleanForm();
      } else {
        this.sendException(response.error);
        this.messageModalError = "Hubo problemas con tu inscripción, por favor vuelve a intentarlo.";
        this.modalError = true;
      }
      this.resetLoading();
    },
    resetLoading() {
      this.loading = false;
      this.fileUpload = false;
      this.progressUpload = 0;
    },
    validatePrefixVoucher() {
      let valid = true;
      if (this.getCampaign.stores) {
        let store = this.getCampaign.stores.find((store) => {
          return store.name === this.record.store;
        });
        if (store) {
          valid = false;
          let serie;
          let correlative;
          let voucher_serie = this.record.voucher.toUpperCase().substring(0, 4);
          let voucher_correlative = parseInt(this.record.voucher.substring(4, this.record.voucher.length));
          let prefixs = store.prefix_voucher.split(",");
          for (var i = 0; i < prefixs.length; i++) {
            serie = prefixs[i].substring(0, 4);
            correlative = parseInt(prefixs[i].substring(4, prefixs[i].length));
            if (voucher_serie === serie && voucher_correlative >= correlative) {
              valid = true;
              break;
            }
          }
          if (!valid) {
            this.sendException({ code: "VOUCHER-INVALID", voucher: this.record.voucher });
            this.prefixVoucherValid = false;
            this.$refs.formParticipate.validate();
            this.prefixVoucherValid = true;
            this.loading = false;
          }
        }
      }
      return valid;
    },
    async checkParticipation() {
      if (this.getCampaign.type === "tokenized" || this.getCampaign.type === "only_terms") {
        this.participate_record = await this.getRecord({ campaign_id: this.getCampaign.id, user_id: this.getUser.uid });
        if (this.participate_record) {
          if (this.getCampaign.type === "tokenized") this.token = this.participate_record.token;
          if (this.getCampaign.show_ranking)
            this.records = await this.getCampaignRanking({
              top: 10,
              campaign_id: this.getCampaign.id,
              user_id: this.getUser.uid,
            });
        }
      }
      this.participation = true;
    },
    sendException(error) {
      if (this.$ga) this.$ga.exception(JSON.stringify(error));
    },
    formatDate(date) {
      return dateFormat(date.toDate(), "dd-mm-yyyy");
    },
    formatCurrency(amount) {
      return formatCurrency(amount);
    },
    cleanForm() {
      this.record.voucher = "";
      this.record.image = "";
      this.record.amount = "";
      this.record.miles = 0;
      this.record.store = "";
      this.imageName = "";
      this.$refs.formParticipate.resetValidation();
    },
  },
};
</script>
<style scoped>
td.selected {
  font-weight: bold !important;
  color: black;
  cursor: default !important;
}
td {
  color: rgb(165, 165, 165);
  cursor: no-drop !important;
}
table {
  border: 1px solid #1b0088;
}
td.me {
  background-color: #1b0088;
  color: white;
  font-weight: bold !important;
  cursor: no-drop !important;
}

.theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
  background: none;
}

.border-primary {
  border: 1px solid #1b0088;
}
.border-round {
  border-radius: 30px;
}
.size16 {
  font-size: 16px;
}
.v-input--checkbox .v-label {
  font-size: 14px;
}
.w100 {
  width: 100%;
}
.pointer input {
  cursor: pointer !important;
}
.terms {
  width: 100%;
  text-align: left;
  margin-bottom: 0;
  padding-top: 10px;
}
.underline {
  text-decoration: underline;
}
.registrate {
  /* position: absolute; */
  left: 0;
  right: 0;
  z-index: 1;
  top: 25%;
}
.shadow-lg {
  -webkit-box-shadow: 0 9px 25px 0 rgba(0, 0, 0, 0.03), 0 5px 8px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 9px 25px 0 rgba(0, 0, 0, 0.03), 0 5px 8px 0 rgba(0, 0, 0, 0.09);
}
.banner-detail {
  height: 360px;
  background-position: center !important;
  background-size: auto 100% !important;
  background-repeat: no-repeat !important;
  background-color: white !important;
}

.border-content {
  border: 1px solid rgba(27, 0, 136, 0.25) !important;
}
@media (max-width: 768px) {
  .content-form {
    margin: 0 !important;
    padding: 16px !important;
  }
  .img-brand-campaign {
    margin: auto !important;
  }
  .banner-detail {
    height: 250px;
  }
}
</style>
