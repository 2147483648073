<template>
  <v-app>
    <!-- Banner -->
    <v-layout white>
      <v-container>
        <v-card>
          <div class="pa-5 content_term">
            <h1 class="headline mb-4 pb-2 font-weight-black">Política de Privacidad</h1>
            <p class="text-xs-left pb-2">
              La presente Política de Privacidad establece la base y condiciones para el procesamiento de sus Datos
              Personales por el uso del proyecto y sitio web GANAPASS (el “Proyecto”, el “Sitio Web”, la “Aplicación” o
              “GANAPASS”), de conformidad con lo establecido en la Ley N° 29733 – “Ley de Protección de Datos
              Personales”, y su Reglamento, Decreto Supremo N° 003-2013-JUS y las demás disposiciones complementarias.
              <br />
              <br />
              El responsable por el tratamiento de sus datos personales será UNA SOLUTIONS S.A.C., con RUC No.
              20554157905, y domicilio en y domicilio en Avenida Ricardo Palma 248, Oficina 801, Miraflores, Lima, (“UNA
              SOLUTIONS”, o “la empresa responsable”).

              <br />
              <br />
              Con la aceptación de la presente Política de Privacidad, la empresa responsable obtiene de cada usuario su
              consentimiento libre, expreso, previo informado e inequívoco para el tratamiento de sus datos personales,
              de conformidad con las condiciones detalladas en el presente documento. Dicho consentimiento estará
              vigente hasta que el usuario revoque la autorización otorgada.
            </p>
            <h2 class="subheading text-xs-left font-weight-black mb-2 underline">
              Datos personales recopilados y utilizados
            </h2>
            <p class="text-xs-left pb-2">
              A través del proceso de registro de cada usuario en el Sitio Web, UNA SOLUTIONS accederá a los siguientes
              Datos Personales:
            </p>

            <v-list subheader three-line>
              <v-list-tile>
                <v-list-tile-action>
                  <p>1.-</p>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-sub-title
                    >Nombres, apellidos, fecha de nacimiento, número de documento de identidad, correo electrónico y
                    teléfono, los cuales son cedidos durante el proceso de registro de usuario.</v-list-tile-sub-title
                  >
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-action>
                  <p>2.-</p>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-sub-title
                    >GANAPASS también recibe y registra información que automáticamente nos envía el navegador que
                    utiliza, incluyendo su Dirección IP y las cookies del Sitio Web.
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-action>
                  <p>3.-</p>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-sub-title
                    >Información sobre sus actividades en la web (páginas visitadas, búsquedas realizadas, historial de
                    participación, entre otros).
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
            <p class="text-xs-left pb-2">
              El tratamiento de Los Datos Personales se realizará cumpliendo los principios y disposiciones establecidas
              en la Ley y su Reglamento, y constituyen un aspecto esencial para la ejecución de los Términos y
              Condiciones
              <a href="/terms" class="primary--text font-weight-black">https://ganapass.com/terms. </a>
            </p>
            <p class="text-xs-left pb-2">
              Los Datos Personales del usuario podrán ser utilizados por la empresa responsable con la siguiente
              finalidad:
            </p>
            <v-list subheader three-line>
              <v-list-tile>
                <v-list-tile-action>
                  <p>-</p>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-sub-title
                    >Personalizar contenido, enviar publicidad, noticias e información acerca de los servicios de
                    GANAPASS, así como de las Promociones Comerciales que publiciten los Organizadores, las cuales
                    pudiesen ser de su interés. Dicha información podrá ser enviada al Usuario por correos electrónicos,
                    mensajes de texto, notificaciones push y/o llamadas telefónicas.
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-action>
                  <p>-</p>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-sub-title
                    >Facilitar su participación en las Promociones Comerciales que son publicitadas por los
                    Organizadores en GANAPASS. En tal sentido, podrá enviar notificaciones y/o mensajes al Usuario con
                    el fin de informarle sobre el estado de su participación en las Promociones Comerciales.
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-action>
                  <p>-</p>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-sub-title
                    >Mejora de los servicios y procedimientos de GANAPASS y UNA SOLUTIONS.
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
            <p class="text-xs-left pb-2">
              En cualquier momento, los Usuarios de GANAPASS podrán solicitar la baja de su cuenta y la eliminación de
              su información de las bases de datos de GANAPASS.
              <br />
              <br />
              El usuario acepta que las empresas responsables podrán acceder, recopilar, registrar, organizar,
              almacenar, conservar, elaborar, modificar, bloquear, suprimir, extraer, consultar, utilizar, transferir o
              procesar sus datos personales de cualquier otra forma prevista por la Ley.
            </p>
            <h2 class="subheading text-xs-left font-weight-black mb-2 underline">
              Compartir Datos Personales e información del Usuario
            </h2>
            <p class="text-xs-left pb-2">
              UNA SOLUTIONS no vende ni comercializa información personal de sus usuarios. Sin perjuicio de ello, la
              empresa responsable compartirá los datos personales que nos proporcione el Usuario con terceros en los
              siguientes supuestos:
            </p>

            <v-list subheader three-line>
              <v-list-tile>
                <v-list-tile-action>
                  <p>1.-</p>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-sub-title
                    >Cuando resulte necesario para la ejecución de las Promociones Comerciales en las que el Usuario se
                    hubiese inscrito como participante. En tal sentido, UNA SOLUTIONS podrá compartir los datos
                    personales del Usuario con las empresas que actúen como Organizador de una Promoción Comercial, a
                    efectos de hacer posible la participación del Usuario y su contacto como potencial ganador.
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-action>
                  <p>2.-</p>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-sub-title
                    >Cuando resulte necesario para facilitar y permitir la entrega de los premios a los que hubiese
                    accedido el Usuario en virtud de una Promoción Comercial. Entre ellos, UNA SOLUTIONS podrá compartir
                    los datos personales del Usuario con LATAM AIRLINES GROUP S.A. SUCURSAL PERÚ, para facilitar la
                    creación de su membresía como socio del Programa LATAM Pass.
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-action>
                  <p>3.-</p>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-sub-title
                    >Cuando resulte necesario compartir esta información con empresas que sean contratadas por UNA
                    SOLUTIONS y que nos sirven para proporcionarle un servicio personalizado (tales empresas no podrán
                    usar sus datos para ningún fin distinto del especificado por UNA SOLUTIONS).
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-action>
                  <p>4.-</p>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-sub-title
                    >Cuando nos sean requeridos por autoridades, sean judiciales, administrativas, policiales, o de
                    cualquier otra naturaleza.
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
            <h2 class="subheading text-xs-left font-weight-black mb-2 underline">
              Plazo
            </h2>
            <p class="text-xs-left pb-2">
              UNA SOLUTIONS conservará y tratará los datos hasta el usuario titular revoque su consentimiento.
            </p>
            <h2 class="subheading text-xs-left font-weight-black mb-2 underline">
              Derechos del titular de los Datos Personales
            </h2>
            <p class="text-xs-left pb-2">
              En cualquier momento, el usuario podrá ejercer sus derechos de información, acceso, actualización,
              inclusión, rectificación, supresión y oposición de sus datos personales. Para tal finalidad, el titular de
              los datos personales deberá solicitarlo a través de un correo electrónico a la dirección
              <a href="mailto:legal-ganapass@unasolutions.com " class="font-weight-black"
                >legal-ganapass@unasolutions.com</a
              >, y adjuntar una fotocopia de su documento de identidad.
            </p>
            <h2 class="subheading text-xs-left font-weight-black mb-2 underline">
              Medidas de seguridad y confidencialidad
            </h2>
            <p class="text-xs-left pb-2">
              UNA SOLUTIONS cumple con las medidas de seguridad y confidencialidad requeridas por la normativa de
              aplicación para evitar la alteración, pérdida, tratamiento o acceso no autorizado a sus datos personales.
              <br />
              <br />
              La empresa responsable se reserva el derecho de modificar su Política de Privacidad en cualquier momento,
              los cuales deberán ser informados oportunamente por medio de su correo electrónico. En caso el titular no
              acepte la actualización de las Políticas de Privacidad, no podrá seguir utilizando los servicios puestos a
              su disposición mediante el Sitio Web.
            </p>
          </div>
        </v-card>
      </v-container>
    </v-layout>
  </v-app>
</template>
<script>
export default {
  name: "Privacity",
};
</script>
<style>
@media (max-width: 768px) {
  .content_term {
    padding: 16px !important;
  }
}
</style>
