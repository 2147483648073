var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-layout",
        { attrs: { white: "" } },
        [
          _c(
            "v-container",
            [
              _c("v-card", [
                _c(
                  "div",
                  { staticClass: "pa-5 content_term" },
                  [
                    _c(
                      "h1",
                      { staticClass: "headline mb-4 pb-2 font-weight-black" },
                      [
                        _vm._v("Términos y Condiciones de "),
                        _c("b", [_vm._v(" GANAPASS ")])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "h2",
                      {
                        staticClass:
                          "subheading text-xs-left font-weight-black mb-2 underline"
                      },
                      [_vm._v("Condiciones generales")]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-xs-left pb-2" }, [
                      _vm._v(
                        "\n            El presente documento establece los Términos y Condiciones de uso (“los Términos y Condiciones”) del\n            proyecto y sitio web\n            "
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "primary--text font-weight-bold",
                          attrs: { href: "www.ganapass.com" }
                        },
                        [_vm._v("www.ganapass.com")]
                      ),
                      _vm._v(
                        " (la “Aplicación”,\n            el “Sitio Web”, o “"
                      ),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("GANAPASS")
                      ]),
                      _vm._v(
                        " ”). Los Términos y Condiciones\n            constituyen un acuerdo, que será plenamente válido entre\n            "
                      ),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("UNA SOLUTIONS S.A.C.")
                      ]),
                      _vm._v(
                        ", con RUC No. 20554157905, y domicilio en\n            Avenida Ricardo Palma 248, Oficina 801, Miraflores, Lima, (en adelante, “UNA SOLUTIONS”) y los usuarios\n            visitantes que acceden al Sitio Web (en adelante, el “Usuario” o los “Usuarios”).\n            "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n            Usted reconoce que la aceptación de los presentes Términos y Condiciones, así como de nuestra Política de\n            Privacidad\n            "
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "font-weight-bold primary--text",
                          attrs: { href: "/privacy-policy" }
                        },
                        [_vm._v(" https://ganapass.com/privacy-policy ")]
                      ),
                      _vm._v(
                        ", constituyen requisitos obligatorios para que los Usuarios puedan hacer uso de cualquiera de las\n            características y funciones que ofrece el Sitio Web, descritos a continuación\n            "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n            Al acceder y registrarse en el Sitio Web, o al utilizar cualquiera de sus servicios y funciones, usted\n            declara conocer y aceptar en su totalidad el contenido de los presentes Términos y Condiciones, así como\n            de nuestra Política de Privacidad.\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "h2",
                      {
                        staticClass:
                          "subheading text-xs-left font-weight-black mb-2 underline"
                      },
                      [_vm._v("Sobre el Sitio Web")]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-xs-left pb-2" }, [
                      _vm._v(
                        "\n            La Aplicación es una plataforma virtual administrada por UNA SOLUTIONS, la cual publicita y pone a\n            disposición de sus Usuarios visitantes un catálogo que les permite visualizar y acceder a distintas\n            promociones comerciales (en adelante, las “Promociones Comerciales”), las cuales son organizadas y\n            publicadas por terceros proveedores (en adelante, los “Organizadores”).\n            "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n            En este sentido, la plataforma GANAPASS constituye una plataforma de intermediación comercial que ofrece a\n            los usuarios visitantes la posibilidad de buscar, visualizar, y participar en distintas Promociones\n            Comerciales, las cuales se regirán por las bases, términos y condiciones que previamente haya definido su\n            respectivo Organizador.\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "h2",
                      {
                        staticClass:
                          "subheading text-xs-left font-weight-black mb-2 underline"
                      },
                      [_vm._v("Sobre los Organizadores")]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-xs-left pb-2" }, [
                      _vm._v(
                        "\n            Los Organizadores son personas jurídicas o naturales independientes de UNA SOLUTIONS, quienes definen por\n            cuenta propia las bases, premios, mecánicas, plazos, términos y demás condiciones que regirán las\n            Promociones Comerciales que publiciten a través de GANAPASS. Los Organizadores podrán ser plenamente\n            identificados por los Usuarios en las condiciones y publicaciones de sus Promociones Comerciales.\n            "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n            Los Organizadores son los únicos y exclusivos responsables de la correcta ejecución de las Promociones\n            Comerciales que hayan publicado en el Sitio Web, así como del cumplimiento de cualquiera de las\n            obligaciones que hubiesen asumido frente a los Usuarios, incluyendo -pero no limitándose- a la entrega\n            oportuna de los premios ofrecidos.\n            "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n            UNA SOLUTIONS no es responsable por el cumplimiento de las obligaciones asumidas por los Organizadores ni\n            por el contenido de sus publicaciones. Cada Organizador será el único responsable por los daños o\n            perjuicios que pudiese causar al Usuario dentro del ámbito de la ejecución de sus Promociones Comerciales.\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "h2",
                      {
                        staticClass:
                          "subheading text-xs-left font-weight-black mb-2 underline"
                      },
                      [_vm._v("Uso del sitio web")]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-xs-left pb-2" }, [
                      _vm._v(
                        "\n            Para poder hacer uso del Sitio Web, cada Usuario deberá:\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-list",
                      { attrs: { subheader: "", "three-line": "" } },
                      [
                        _c(
                          "v-list-tile",
                          [
                            _c("v-list-tile-action", [
                              _c("p", [_vm._v("1.-")])
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-list-tile-content",
                              [
                                _c("v-list-tile-sub-title", [
                                  _vm._v(
                                    "Completar un proceso de registro, donde se le solicitará ingresar sus datos personales (nombres,\n                  apellidos, documento de identidad, fecha de nacimiento, número de teléfono y correo electrónico),\n                  aceptando los Términos y Condiciones y la Política de Privacidad de GANAPASS. Una vez concluido el\n                  proceso de registro, el Usuario podrá iniciar sesión haciendo uso de su usuario y\n                  contraseña."
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-tile",
                          [
                            _c("v-list-tile-action", [
                              _c("p", [_vm._v("2.-")])
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-list-tile-content",
                              [
                                _c("v-list-tile-sub-title", [
                                  _vm._v(
                                    "Luego de iniciar sesión, el usuario deberá seleccionar la publicación de su interés, donde podrá\n                  acceder a la información y las bases de la Promoción Comercial publicadas por cada Organizador. Así\n                  como también, recibirá comunicaciones de las campañas y promociones gestionadas por GanaPass en las\n                  cuales también podrá participar.\n                "
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-tile",
                          [
                            _c("v-list-tile-action", [
                              _c("p", [_vm._v("3.-")])
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-list-tile-content",
                              [
                                _c("v-list-tile-sub-title", [
                                  _vm._v(
                                    "Para participar de una Promoción Comercial, el Usuario deberá completar todos los campos y\n                  presentar la información obligatoria que le fuese solicitada por el Organizador, incluyendo (según\n                  cada caso) sus datos de contacto, el número de la boleta de compra, el importe de la compra\n                  realizada, la foto de la boleta de compra, u otros que fuesen requeridos por el Organizador de la\n                  Promoción Comercial. Así como también, aprobar los términos y condiciones correspondientes a cada\n                  campaña.\n                "
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "h2",
                      {
                        staticClass:
                          "subheading text-xs-left font-weight-bold mt-2 underline mb-2"
                      },
                      [_vm._v("Millas LATAM Pass")]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-xs-left pb-4 mb-0" }, [
                      _vm._v(
                        "\n            Algunas Promociones Comerciales publicadas por los Organizadores en GANAPASS podrán ofrecer a los Usuarios\n            -como premio- la entrega de Millas LATAM Pass (en adelante, las “Millas” o “Millas LATAM Pass”). Las\n            millas LATAM Pass constituyen la unidad de medida del Programa LATAM Pass, administrado exclusivamente por\n            "
                      ),
                      _c("b", [
                        _vm._v("LATAM AIRLINES GROUP S.A. SUCURSAL PERÚ ")
                      ]),
                      _vm._v(
                        "(en adelante, “LATAM”). Para más información sobre el\n            Programa LATAM Pass, usted puede acceder a la siguiente URL:\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "pa-4 text-xs-left font-weight-black",
                        attrs: {
                          href:
                            "https://www.latam.com/es_pe/latam-pass/sobre-el-programa/terminos-y-condiciones/"
                        }
                      },
                      [
                        _vm._v(
                          "\n            https://www.latam.com/es_pe/latam-pass/sobre-el-programa/terminos-y-condiciones/\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-xs-left pb-2 pt-4" }, [
                      _vm._v(
                        "\n            En caso que el Usuario se inscriba a una Promoción Comercial donde el Organizador ofrezca Millas LATAM\n            Pass, y fuese seleccionado como ganador, el Usuario deberá ser titular de una cuenta de socio LATAM Pass\n            para que el organizador pueda hacer efectiva la entrega de Millas.\n            "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n            En el supuesto que el Usuario seleccionado como ganador no contase con una cuenta de socio LATAM Pass, UNA\n            SOLUTIONS y/o el Organizador podrán coordinar con LATAM la creación de una membresía como socio del\n            Programa LATAM Pass, a fin de que el Organizador pueda hacer efectiva la entrega de sus Millas, aceptando\n            los términos y condiciones del programa de Millas LATAM Pass mencionados anteriormente.\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "h2",
                      {
                        staticClass:
                          "subheading text-xs-left font-weight-black mb-2 underline"
                      },
                      [_vm._v("Otras consideraciones")]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-xs-left pb-2" }, [
                      _vm._v(
                        "\n            Al aceptar los Términos y Condiciones,el usuario declara ser mayor de edad de conformidad con las leyes\n            peruanas vigentes, lo cual constituye un requisito para la participación en la promoción. Cualquier\n            usuario que no cumpla con este requisito, será descalificado.\n            "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n            En caso que UNA SOLUTIONS identifique un uso inadecuado del Sitio Web por parte de un usuario, o el uso de\n            algún mecanismo que busque defraudar las mecánicas de participación establecidas en los Términos y\n            Condiciones, UNA SOLUTIONS podrá suspender, descalificar y/o bloquear al usuario infractor de manera\n            definitiva y restringirlo de acceder al premio en cualquier momento y etapa de la promoción, incluso\n            después de la selección de ganadores. Esta situación será comunicada a dicho usuario.\n            "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n            El Sitio Web puede contener hipervínculos o Links a otros sitios web, que no sean controlados, editados,\n            ni tengan relación alguna con UNA SOLUTIONS, por lo cual la empresa no será responsable del contenido ni\n            de la información contenida en ellos. La función de los links que se encuentran en el Sitio Web será\n            meramente informativa y es proporcionada por terceros, siendo responsabilidad de cada Organizador el\n            contenido de los Links que incluya en su publicación.\n            "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n            Los usuarios que fuesen seleccionados como ganadores en una Promoción Comercial publicada en GANAPASS,\n            autorizan a UNA SOLUTIONS y al Organizador para publicar sus datos en cualquier medio de comunicación, con\n            fines relacionados a la promoción y ejecución de la Promoción Comercial.\n            "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n            En caso exista una modificación de los presentes Términos y Condiciones o de la Política de Privacidad,\n            UNA SOLUTIONS informará a los usuarios sobre dicho cambio. En caso ello ocurra, la continuidad de uso del\n            servicio y el acceso al Sitio Web implicarán la aceptación y conformidad del usuario con cualquier\n            modificación que pudiese haber surtido efecto.\n            "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n            El presente acuerdo se regirá por la legislación especial sobre la materia de la República del Perú.\n            "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n            Para todo lo relativo a cualquier controversia o desacuerdo que surja respecto del presente acuerdo entre\n            UNA SOLUTIONS y el Usuario, ambas partes renuncian expresamente al fuero que por razón de su domicilio o\n            por cualquier otro motivo pudiera corresponderle. En ese sentido, los Términos y Condiciones se regirán\n            por el ordenamiento jurídico peruano y, particularmente, por el Código Civil. El usuario reconoce que\n            cualquier desavenencia o desacuerdo con UNA SOLUTIONS será sometida a la jurisdicción de los jueces y\n            tribunales de justicia de Lima Cercado.\n            "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n            Las partes acuerdan que la invalidez o nulidad de cualquiera de las cláusulas del presente acuerdo no\n            acarrea el mismo efecto para el resto de las cláusulas, entendiéndose la plena vigencia del acuerdo con\n            independencia de la disposición que resulte invalidada o nula. Asimismo, la cláusula inválida o nula será\n            limitada o eliminada en la medida mínima necesaria para que el acuerdo permanezca en plena vigencia y de\n            obligado cumplimiento.\n            "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n            En caso de cualquier duda o comentario sobre los Términos y Condiciones, usted podrá comunicarse con UNA\n            SOLUTIONS por medio de\n            "
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "font-weight-black",
                          attrs: {
                            href: "mailto:legal-ganapass@unasolutions.com "
                          }
                        },
                        [_vm._v("legal-ganapass@unasolutions.com")]
                      )
                    ])
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }