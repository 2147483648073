var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("Navbar"),
      _vm._v(" "),
      _vm.getCategory
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    {
                      staticClass: "mt-60 banner-detail",
                      style: _vm.imgSection
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", md5: "" } },
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    row: "",
                                    wrap: "",
                                    "pt-3": "",
                                    "pb-0": "",
                                    "px-2": "",
                                    "text-sm-left": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            row: "",
                                            wrap: "",
                                            "text-sm-left": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: { "md-5": "", "xs-12": "" }
                                            },
                                            [
                                              _vm.getCategory.logo
                                                ? _c("v-img", {
                                                    attrs: {
                                                      src: _vm.getCategory.logo,
                                                      width: "140"
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "h2",
                                        {
                                          staticClass:
                                            "white--text font-weight-black pb-3 display-1"
                                        },
                                        [_vm._v(_vm._s(_vm.getCategory.name))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "h2",
                                        {
                                          staticClass:
                                            "white--text font-weight-black pb-2 subheading"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.getCategory.description)
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                { attrs: { xs12: "", "py-4": "" } },
                [
                  _vm.getCampaigns
                    ? _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-container",
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        row: "",
                                        wrap: "",
                                        "justify-center": ""
                                      }
                                    },
                                    _vm._l(_vm.getCampaigns, function(
                                      campaign,
                                      i
                                    ) {
                                      return _c(
                                        "v-flex",
                                        {
                                          key: i,
                                          staticStyle: { position: "relative" },
                                          attrs: {
                                            xs12: "",
                                            sm6: "",
                                            md4: "",
                                            "pa-3": "",
                                            "d-flex": ""
                                          }
                                        },
                                        [
                                          _c("CardCampaign", {
                                            attrs: { campaign: campaign }
                                          })
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.getCampaigns.length == 0
                                    ? _c(
                                        "v-layout",
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _vm._v(
                                                "\n                Aún no hay campañas en las que puedas participar\n              "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-layout",
            {
              attrs: {
                row: "",
                "fill-height": "",
                "align-center": "",
                "xs-minvh90": ""
              }
            },
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/landing/loading.gif"),
                    height: "100"
                  }
                })
              ])
            ],
            1
          ),
      _vm._v(" "),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }